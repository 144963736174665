import { Input } from "@headlessui/react";
import { useRef, useState } from "react";

export default function EditableTextInput({
  className,
  value,
  setValue,
  readOnly,
  placeholder,
  alwaysEditing = false,
}) {
  const [inputValue, setInputValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);

  const handleOnClick = () => {
    setIsEditing(true);
  };
  const handleBlur = () => {
    setValue(inputValue);
    setIsEditing(false);
  };
  const handleKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      handleBlur();
      e.preventDefault();
      e.currentTarget.blur();
    } else if (e.key === "Escape") {
      setInputValue(value);
      setIsEditing(false);
      e.currentTarget.blur();
    }
  };

  return readOnly ? (
    <div className={"rounded-md " + className}>{value}</div>
  ) : (
    <>
      {(isEditing || alwaysEditing) && (
        <Input
          aria-hidden={!(isEditing || alwaysEditing)}
          className={"w-full aria-hidden:hidden " + className}
          value={inputValue}
          onKeyDown={handleKeyDown}
          onChange={(e) => setInputValue(e.target.value)}
          autoFocus={!alwaysEditing}
          onBlur={handleBlur}
        />
      )}
      <div
        aria-hidden={isEditing || alwaysEditing}
        onClick={handleOnClick}
        className={
          "hover:ring-fuchsia hover:ring-2 aria-hidden:hidden rounded-md " +
          className
        }
      >
        {value || placeholder}
      </div>
    </>
  );
}
