import { EditableTitle, ErrorPage, LoadingPage, Title } from "components";
import { Tooltip } from "@/components/styled/tooltip";
import {
  delete_project,
  get_profile,
  get_project,
  update_project_name,
} from "api";
import { useNavigate, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import {
  Button,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";

// import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { FaEllipsisV } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useState } from "react";
import IdeaCard from "./project-cards/IdeaCard";
import MoreComingSoonCard from "./project-cards/MoreComingSoon";
import ShareProject from "./share-project/ShareProject";
import useSWRMutation from "swr/mutation";
import AdminCard from "./project-cards/AdminCard";
import { Dialog } from "@/components/styled/dialog";
import GrantCard from "./project-cards/GrantCard";
import { PageHelp } from "@/components/styled/pageHelp";
import { ScrollArea } from "@/components/ui/scroll-area";

export default function Project() {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const {
    data: project,
    isLoading: isProjectLoading,
    error: isProjectError,
  } = useSWR(
    `get_project/${projectId}`,
    async () => await get_project({ project_id: projectId })
  );

  const {
    data: profile,
    isLoading: isProfileLoading,
    error: isProfileError,
  } = useSWR(`get_profile`, get_profile);

  const { error: isRenameProjectError, trigger: renameProject } =
    useSWRMutation(
      `update_project_name/${projectId}`,
      async (_, { arg }) => {
        await update_project_name({ project_id: projectId, name: arg });
        return arg;
      },
      {
        onSuccess: (newName) => {
          mutate(
            `get_project/${projectId}`,
            (data) => {
              return { ...data, name: newName };
            },
            { revalidate: false }
          );
          mutate(
            (key) => key?.startsWith("list_projects"),
            (data) =>
              data?.map((p) =>
                p.project_id === parseInt(projectId)
                  ? { ...p, project_name: newName }
                  : p
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: isDeleteProjectError, trigger: deleteProject } =
    useSWRMutation(
      `delete_project`,
      () => delete_project({ project_id: projectId }),
      {
        onSuccess: () => {
          mutate("count_project", undefined, { revalidate: false });
          mutate((key) => key?.startsWith("list_projects"), undefined, {
            revalidate: false,
          });
          navigate("/");
        },
      }
    );

  if (isProjectLoading || isProfileLoading) return <LoadingPage />;
  if (isProjectError || isProfileError) return <ErrorPage />;

  const hasEditorAccess =
    project?.users.find((p) => p.user_id === profile.user_id).relationship !==
    "viewer";

  const handleTitleChange = async (value) => {
    if (value === project.name) return; // If project name is the same, don't send a request
    renameProject(value);
  };

  const handleDeleteProject = async () => {
    deleteProject();
  };

  const idea_tile = project.tiles.find((tile) => tile.type === "idea_tile");

  return (
    <div className="flex flex-col gap-4 grow shrink overflow-hidden h-full">
      <div className="bg-white flex flex-col rounded-lg p-4 gap-y-4 w-full h-full">
        <div className="flex justify-between w-full h-fit">
          {hasEditorAccess ? (
            <EditableTitle
              className="text-4xl"
              value={project.name}
              setValue={handleTitleChange}
            />
          ) : (
            <Title className="text-4xl" value={project.name} />
          )}
          <div className="flex items-center gap-x-2">
            <Dialog
              trigger={<MdDelete className="hover:text-red-500" />}
              title="Are you sure you want to delete your project?"
              content={
                <div className="flex flex-col gap-y-4">
                  You will not be able to recover your data after deleting this
                  project.
                  <div className="flex gap-4 w-full justify-between">
                    <button
                      className="btn-fuchsia"
                      onClick={handleDeleteProject}
                    >
                      Delete Project
                    </button>
                  </div>
                  {isDeleteProjectError && (
                    <span className="text-red-500">
                      There was an issue deleting this project. Please try
                      again.
                    </span>
                  )}
                </div>
              }
            />
            <ShareProject
              hasEditorAccess={hasEditorAccess}
              projectId={projectId}
              users={project.users}
              project_name={project.name}
            />
            <PageHelp
              title="Dashboard"
              content={
                <div className="flex flex-col gap-y-4 text-black">
                  <div>
                    A project is a collaborative space for you to work on your
                    grant proposal. Think of a project as one main idea that you
                    will use to apply to one grant application. Each project has
                    a few different spaces for you to work in.
                  </div>
                  <div>
                    <h2 className="font-semibold">Ideation Space</h2>
                    This is the area for you to help us understand what your
                    idea is, along with being able to brainstorm and really
                    refine what you want to research.
                  </div>
                  <div>
                    <h2 className="font-semibold">Grant Space</h2>
                    This is the space for you to see which grants are available
                    and to select a grant that you would like to apply to.
                  </div>
                  <div>
                    <h2 className="font-semibold">Admin Space</h2>
                    The Admin Space is a way for you to keep track of everything
                    that you and your collaborators need to do and all of the
                    required files that you have to hand in.
                  </div>
                  <div>
                    Now each project is meant to be collaborative space so
                    remember that you can share them with your collaborators as
                    well.
                  </div>
                </div>
              }
            />
          </div>
        </div>
        {isRenameProjectError && (
          <span className="text-red-500">
            There was an issue updating this project name. Please try again.
          </span>
        )}

        <ScrollArea className="flex grow">
          <div className="flex flex-row flex-wrap gap-4 p-4">
            {idea_tile && (
              <IdeaCard projectId={projectId} idea={idea_tile.idea} />
            )}
            {project.tiles.find((tile) => tile.type === "grant_tile") && (
              <GrantCard projectId={projectId} />
            )}
            {project.tiles.find((tile) => tile.type === "admin_tile") && (
              <AdminCard projectId={projectId} />
            )}
          </div>
        </ScrollArea>
        <div className="flex justify-end">
          <MoreComingSoonCard />
        </div>
      </div>
    </div>
  );
}
