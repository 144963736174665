import { FaExternalLinkAlt } from "react-icons/fa";
function l({ url, children }) {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => e.stopPropagation()}
      className="text-2xl underline font-semibold hover:text-fuchsia"
    >
      {children}
      <span className="inline-flex h-4 w-4 ml-1">
        <FaExternalLinkAlt />
      </span>
    </a>
  );
}
export { l as ExternalLink };
