import { FaExternalLinkAlt, FaRegBookmark } from "react-icons/fa";

import { useState } from "react";
import { unbookmark_project_call } from "api";
import useSWRMutation from "swr/mutation";
import { LoadingSpinner } from "components";
import { mutate } from "swr";

import { decode } from "html-entities";
import SlideUp from "components/animations/SlideUp";
import { Dialog } from "@/components/styled/dialog";
import { remove_timestamp } from "@/lib/utils";

export default function Call({
  agency,
  call_id,
  close_date,
  description,
  bookmarked,
  title,
  url,
  posted_date,
  projectId,
}) {
  const {
    isMutating: isUnbookmarking,
    error: unbookmarkError,
    trigger: unbookmark,
  } = useSWRMutation(
    `unbookmark_call/${projectId}/${call_id}`,
    async () =>
      unbookmark_project_call({ project_id: projectId, call_id: call_id }),
    {
      onSuccess: () => {
        setTimeout(() => {
          mutate(
            `get_project_calls/${projectId}`,
            (d) => ({
              recommendedCalls: [
                ...d.recommendedCalls,
                {
                  title,
                  agency,
                  description,
                  close_date,
                  bookmarked: false,
                  call_id,
                  url,
                  posted_date,
                },
              ],
              bookmarkedCalls: d.bookmarkedCalls.filter(
                (c) => c.call_id !== call_id
              ),
            }),
            { revalidate: false }
          );
        }, 200);
      },
    }
  );

  const newCloseDate = remove_timestamp(close_date);
  const newPostedDate = remove_timestamp(posted_date);

  return (
    <Dialog
      trigger={
        <div className="flex flex-col border-[1px] rounded-lg p-4 gap-y-2 hover:border-fuchsia text-left">
          <div>
            {url ? (
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
                className="text-2xl underline font-semibold hover:text-fuchsia"
              >
                {title}
                <span className="inline-flex">
                  <FaExternalLinkAlt />
                </span>
              </a>
            ) : (
              <span className="text-2xl underline font-semibold">{title}</span>
            )}
          </div>
          <div>{agency}</div>
          <div className="text-base">Posted: {newPostedDate}</div>
          <div className="text-base">Closes: {newCloseDate}</div>

          <div className="break-wordsp-2 text-base">
            {description.substring(0, 300) + "..."}
          </div>
        </div>
      }
      contentClassName="max-w-4xl max-h-screen overflow-y-scroll"
      content={
        <div className="text-black flex flex-col gap-y-2">
          <div>
            {url ? (
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
                className="text-2xl underline font-semibold hover:text-fuchsia"
              >
                {title}
                <span className="inline-flex">
                  <FaExternalLinkAlt />
                </span>
              </a>
            ) : (
              <span className="text-2xl underline font-semibold">{title}</span>
            )}
          </div>
          <div>{agency}</div>
          <div>Posted: {newPostedDate}</div>
          <div>Closes: {newCloseDate}</div>
          <div>
            <button
              className="flex justify-center items-center hover:text-fuchsia hover:underline"
              onClick={(e) => {
                e.stopPropagation();
                unbookmark();
              }}
            >
              {isUnbookmarking ? (
                <>
                  <LoadingSpinner /> Unbookmarking
                </>
              ) : (
                <>
                  <FaRegBookmark /> Unbookmark
                </>
              )}
            </button>
          </div>
          {unbookmarkError && (
            <span className="text-red-500">
              There was an issue unbookmarking this call. Please try again.
            </span>
          )}
          <div className="break-wordsp-2 text-base">{description}</div>
        </div>
      }
    />
  );
}
