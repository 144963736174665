import axiosInstance from "api/axiosInstance";
import axios from "axios";

export const get_recommended_papers = async ({ project_id, paper_id, paper_type }) => {
  const response = await axiosInstance({
    url: "/project/paper/info",
    method: "GET",
    params: {
      project_id: project_id,
      paper_id: paper_id,
      paper_type: paper_type,
    }
  });
  return response.data;
}

/**
 * 
 * @param {*} project_id 
 * @param {*} upload_type
 * @param {*} filename
 * @param {*} team_author
 * @param {*} link
 * @returns 
 */

export const upload_paper_via_link = async({project_id, link, upload_type}) => {
  await axiosInstance({
    url: "/project/paper/add",
    method: "POST",
    data: {
      project_id: project_id,
      upload_type: upload_type,
      link: link,
    }
  });
  return true;
  // return response.data;
}

export const upload_paper_via_file = async({project_id, upload_type, filename, file}) => {
  const response = await axiosInstance({
    url: "/project/paper/add",
    method: "POST",
    data: {
      project_id: project_id,
      upload_type: upload_type,
      filename: filename,
    }
  });
  const { upload_url } = response.data;
  await axios({
    url: upload_url,
    method: "PUT",
    headers: {
      'x-goog-content-length-range': '0,10000000',
      'Content-Type': 'application/pdf'
    },
    data: file
  });
  return true;
}

export const add_recommended_paper = async({project_id, rec_id}) => {
  const response = await axiosInstance({
    url: "/project/paper/add",
    method: "POST",
    data: {
      project_id: project_id,
      upload_type: "rec",
      rec_ids: [rec_id]
    }
  });
  return response.data;
}
export const delete_paper = async({project_id, paper_id}) => {
  const response = await axiosInstance({
    url: "/project/paper/remove",
    method: "DELETE",
    data: {
      project_id: project_id,
      paper_id: paper_id,
    }
  });
  return response.data;
}

export const update_paper_title = async({project_id, paper_id, title}) => {
  const response = await axiosInstance({
    url: "/project/paper/edit",
    method: "POST",
    data: {
      project_id: project_id,
      paper_id: paper_id,
      title: title
    }
  });
  return response.data;
}

export const update_paper_authors = async({project_id, paper_id, authors}) => {
  const response = await axiosInstance({
    url: "/project/paper/edit",
    method: "POST",
    data: {
      project_id: project_id,
      paper_id: paper_id,
      authors: authors
    }
  });
  return response.data;
}

export const update_paper_abstract = async({project_id, paper_id, abstract}) => {
  const response = await axiosInstance({
    url: "/project/paper/edit",
    method: "POST",
    data: {
      project_id: project_id,
      paper_id: paper_id,
      abstract: abstract
    }
  });
  return response.data;
}

export const search_papers = async ({project_id, team, oldest_year, newest_year, authors, engine}) => {
  const response = await axiosInstance({
    url: "/project/paper/find_papers",
    method: "POST",
    data: {
      project_id: project_id,
      oldest_year: oldest_year,
      newest_year: newest_year,
      engine: engine,
      team: team,
      authors: authors
    }
  });
  return response.data;
}

export const get_gcp_paper_link = async ({project_id, paper_id}) => {
  const response = await axiosInstance({
    url: "/project/paper/link",
    method: "GET",
    params: {
      project_id: project_id,
      paper_id: paper_id,
    }
  });
  return response.data;
} 