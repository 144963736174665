import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
/**
 *
 * @param {*} trigger
 * @returns
 */
function d({ trigger, className, title, content, contentClassName }) {
  return (
    <Dialog>
      <DialogTrigger className={className}>{trigger}</DialogTrigger>
      <DialogContent className={contentClassName}>
        <DialogHeader>
          <DialogTitle className="pb-4">{title}</DialogTitle>
          <DialogDescription asChild>
            <div>{content}</div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export { d as Dialog };
