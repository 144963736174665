import {
  Button,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Textarea,
  Transition,
} from "@headlessui/react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";
import {
  delete_paper,
  get_gcp_paper_link,
  update_paper_authors,
  update_paper_title,
  update_paper_abstract,
} from "api";
import { LoadingSpinner } from "components";
import useSWRMutation from "swr/mutation";
import { useRef, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import CreatableSelect from "react-select/creatable";
import { mutate } from "swr";
import SlideUp from "components/animations/SlideUp";
import { Dialog } from "@/components/styled/dialog";

const createOption = (v) => ({ label: v, value: v });

export default function SelectedPaper({
  projectId,
  authors,
  external_paper_url,
  year_published,
  title,
  initial_text,
  paper_id,
  mutatePapers,
}) {
  const [isGetGCPError, setIsGetGCPError] = useState(false);
  const {
    isMutating: isDeleting,
    error: isDeleteError,
    trigger: deletePaper,
  } = useSWRMutation(
    `remove_paper/${projectId}/${paper_id}`,
    () => delete_paper({ project_id: projectId, paper_id: paper_id }),
    {
      onSuccess: () => {
        mutatePapers((data) => data.filter((d) => d.paper_id !== paper_id));
      },
    }
  );

  const {
    isMutating: isTitleUpdating,
    error: isTitleUpdateError,
    trigger: updateTitle,
  } = useSWRMutation(
    `update_title`,
    () =>
      update_paper_title({
        project_id: projectId,
        paper_id: paper_id,
        title: tempTitle,
      }),
    {
      onSuccess: () => {
        mutate(`get_papers?${projectId}`, (d) => {
          d.find((p) => p.paper_id === paper_id).title = tempTitle;
          return [...d];
        });
      },
    }
  );

  const {
    isMutating: isAuthorsUpdating,
    error: isAuthorsUpdatingError,
    trigger: updateAuthors,
  } = useSWRMutation(
    `update_authors`,
    () =>
      update_paper_authors({
        project_id: projectId,
        paper_id: paper_id,
        authors: tempAuthors,
      }),
    {
      onSuccess: () => {
        mutate(`get_papers?${projectId}`, (d) => {
          d.find((p) => p.paper_id === paper_id).authors = tempAuthors;
          return [...d];
        });
      },
    }
  );

  const {
    isMutating: isSummaryUpdating,
    error: isSummaryUpdatingError,
    trigger: updateSummary,
  } = useSWRMutation(
    `update_authors`,
    () =>
      update_paper_abstract({
        project_id: projectId,
        paper_id: paper_id,
        abstract: tempSummary,
      }),
    {
      onSuccess: () => {
        mutate(`get_papers?${projectId}`, (d) => {
          d.find((p) => p.paper_id === paper_id).initial_text = tempSummary;
          return [...d];
        });
      },
    }
  );

  // If there is no external_paper_url, try to generate a GCP link
  const handleGetGCPLink = async () => {
    try {
      setIsGetGCPError(false);
      const { url } = await get_gcp_paper_link({
        project_id: projectId,
        paper_id: paper_id,
      });
      window.open(url, "_blank");
    } catch (e) {
      setIsGetGCPError(true);
    }
  };

  // Title
  const [tempTitle, setTempTitle] = useState(title);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const handleOnBlurTitle = (e) => {
    setIsEditingTitle(false);
    updateTitle();
  };
  const handleOnEditTitle = (e) => {
    e.preventDefault();
    setIsEditingTitle(true);
  };
  const handleOnKeyDownTitle = (e) => {
    e.stopPropagation();
    if (e.code === "Enter") {
      setIsEditingTitle(false);
      updateTitle();
    }
  };

  // Authors
  const [authorValue, setAuthorValue] = useState("");
  const [tempAuthors, setTempAuthors] = useState(authors);
  const [isEditingAuthors, setIsEditingAuthors] = useState(false);

  const handleOnEditAuthors = (e) => {
    e.preventDefault();
    setIsEditingAuthors(true);
  };

  const handleKeyDownAuthors = async (e) => {
    e.stopPropagation();
    if (!authorValue) return;
    if (e.key === "Enter" || e.key === "Tab") {
      setTempAuthors((p) => [...p, authorValue]);
      setAuthorValue("");
    }
  };

  const handleInputChangeAuthors = async (newValue, context) => {
    if (context.action === "input-change") {
      setAuthorValue(newValue);
    } else if (context.action === "input-blur") {
      setAuthorValue("");
    }
  };
  const handleOnChangeAuthors = async (newValue, context) => {
    if (context.action === "remove-value" || context.action === "pop-value") {
      setTempAuthors((authors) =>
        authors.filter((t) => t !== context.removedValue.value)
      );
    }
  };

  const handleOnBlurAuthors = async (e) => {
    setIsEditingAuthors(false);
    // Update only if there was a change
    if (
      tempAuthors.every((v) => authors.includes(v)) &&
      authors.every((v) => tempAuthors.includes(v))
    ) {
      return;
    }
    updateAuthors();
  };

  // Summary
  const [tempSummary, setTempSummary] = useState(initial_text);
  const [isEditingSummary, setIsEditingSummary] = useState(false);
  const handleOnEditSummary = () => {
    setIsEditingSummary(true);
  };
  const fieldRef = useRef(null);
  const contentRef = useRef(null);
  const handleBlurSummary = () => {
    setIsEditingSummary(false);
    updateSummary();
  };
  const handleKeyDownSummary = (e) => {
    switch (e.key) {
      case "Enter":
        handleBlurSummary();
        break;
      case "Escape":
        handleBlurSummary(false);
        break;
      default:
        break;
    }
  };

  return (
    <Dialog
      trigger={
        <div className="flex flex-col border-[1px] rounded-lg p-4 hover:border-fuchsia text-left">
          <div className="flex flex-row w-full">
            <div className="gap-y-1 flex flex-col w-full">
              <div className="flex w-full group">
                <div className="flex flex-col grow w-full">
                  <div className="flex flex-row">
                    {external_paper_url ? (
                      <a
                        onClick={(e) => e.stopPropagation()}
                        href={external_paper_url}
                        rel="noreferrer"
                        target="_blank"
                        className="hover:text-fuchsia hover:underline text-2xl font-semibold text-left"
                      >
                        {title}
                        <span className="inline-flex">
                          <FaExternalLinkAlt className="w-5 h-5" />
                        </span>
                      </a>
                    ) : (
                      <button
                        className="hover:text-fuchsia hover:underline text-2xl font-semibold"
                        onClick={handleGetGCPLink}
                      >
                        {title}
                        <span className="inline-flex">
                          <FaExternalLinkAlt className="w-5 h-5" />
                        </span>
                      </button>
                    )}
                  </div>
                  <div className="flex gap-x-2">
                    {authors ? authors.join(", ") : ""}
                  </div>
                  {isGetGCPError && (
                    <span className="text-red-500">
                      There was an issue getting the link. Please try again.
                    </span>
                  )}
                  {isTitleUpdateError && (
                    <span className="text-red-500">
                      There was an issue updating this title. Please try again.
                    </span>
                  )}
                  {isAuthorsUpdatingError && (
                    <span className="text-red-500">
                      There was an issue updating these authors. Please try
                      again.
                    </span>
                  )}
                  {isSummaryUpdatingError && (
                    <span className="text-red-500">
                      There was an issue updating this summary. Please try
                      again.
                    </span>
                  )}
                  {isDeleting && (
                    <span>
                      Deleting <LoadingSpinner />
                    </span>
                  )}
                  {isDeleteError && (
                    <span className="text-red-500">
                      There was an error deleting this paper. Please try again.
                    </span>
                  )}
                </div>
              </div>

              <div>
                <div className="text-base">
                  <div>
                    {tempSummary ? tempSummary?.substring(0, 300) + "..." : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div
              onClick={(e) => {
                e.stopPropagation();
                deletePaper();
              }}
              className="text-base flex items-center hover:text-fuchsia hover:underline"
            >
              <MdDelete /> Remove Paper
            </div>
          </div>
        </div>
      }
      contentClassName="max-w-4xl max-h-screen overflow-y-scroll"
      content={
        <div className="flex flex-col gap-y-2 text-black">
          <div>
            {!isEditingTitle && (
              <span
                onClick={handleOnEditTitle}
                className="hover:text-fuchsia flex"
              >
                <FaRegEdit />
              </span>
            )}
            {isEditingTitle ? (
              <Textarea
                autoFocus
                onBlur={handleOnBlurTitle}
                onKeyDown={handleOnKeyDownTitle}
                className="text-2xl font-semibold w-full pl-2 rounded-l-md h-fit"
                value={tempTitle}
                onChange={(e) => setTempTitle(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
            ) : external_paper_url ? (
              <a
                href={external_paper_url}
                rel="noreferrer"
                target="_blank"
                className="hover:text-fuchsia hover:underline text-2xl font-semibold text-left"
              >
                {title}
                <span className="inline-flex">
                  <FaExternalLinkAlt className="w-5 h-5" />
                </span>
              </a>
            ) : (
              <button
                className="hover:text-fuchsia hover:underline text-2xl font-semibold"
                onClick={handleGetGCPLink}
              >
                {title}
                <span className="inline-flex">
                  <FaExternalLinkAlt className="w-5 h-5" />
                </span>
              </button>
            )}
          </div>
          <div className="flex gap-x-2">
            {isEditingAuthors ? (
              <div className="w-full" onClick={(e) => e.stopPropagation()}>
                <CreatableSelect
                  styles={{
                    clearIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      height: "100%",
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      transition: "transition",
                      overflowX: "scroll",
                      overflowY: "scroll",
                      maxWidth: "100%",
                      width: "100%",
                      borderColor: state.isFocused ? "fuchsia" : "zinc-300",
                    }),
                  }}
                  components={{
                    DropdownIndicator: null,
                    ClearIndicator: null,
                  }}
                  inputValue={authorValue}
                  autoFocus
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={handleOnChangeAuthors}
                  onInputChange={handleInputChangeAuthors}
                  onKeyDown={handleKeyDownAuthors}
                  onBlur={handleOnBlurAuthors}
                  placeholder="Add a key topic..."
                  value={tempAuthors.map((a) => createOption(a))}
                />
              </div>
            ) : (
              <>{authors ? authors.join(", ") : ""}</>
            )}
            {!isEditingAuthors && (
              <button
                onClick={handleOnEditAuthors}
                className="hover:text-fuchsia flex"
              >
                <FaRegEdit />
              </button>
            )}
          </div>
          <div className="flex items-center justify-between">
            <button
              onClick={deletePaper}
              className="text-base flex items-center hover:text-fuchsia hover:underline"
            >
              <MdDelete /> Remove Paper
            </button>
          </div>
          <div>
            <button
              onClick={handleOnEditSummary}
              className="hover:text-fuchsia text-base inline-flex"
            >
              <FaRegEdit />
            </button>
          </div>
          <div>
            {isEditingSummary ? (
              <Textarea
                ref={fieldRef}
                style={{
                  height:
                    fieldRef.current?.scrollHeight ||
                    contentRef.current?.scrollHeight,
                }}
                className="flex flex-wrap w-full"
                value={tempSummary}
                onKeyDown={handleKeyDownSummary}
                onChange={(e) => setTempSummary(e.target.value)}
                autoFocus
                onBlur={handleBlurSummary}
              />
            ) : (
              <div ref={contentRef}>{tempSummary}</div>
            )}
          </div>
        </div>
      }
    />
  );
}
