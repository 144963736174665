export default function TextArea({ className, title, placeholder, input, setInput, maxlength, readOnly }) {
  return (
    <label className="block">
      <span className="font-bold">{title}</span>
      <textarea
        readOnly={readOnly}
        value={input}
        maxLength={maxlength}
        onChange={(e) => setInput(e.target.value)}
        className={"py-1 px-2 block w-full h-60 border-zinc-300 border-2 rounded-md " + className}
        placeholder={placeholder}
      />
    </label>
  );
}
