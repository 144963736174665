import axiosInstance from "./axiosInstance"

export const get_general = async ({ project_id, tile_id }) => {
  const response = await axiosInstance({
    url: "/project/idea/general",
    method: "GET",
    params: {
      project_id: project_id,
      tile_id: tile_id
    }
  });
  return response.data;
}

export const update_general = async ({ project_id, tile_id, idea, description }) => {
  const response = await axiosInstance({
    url: "/project/idea/general",
    method: "POST",
    data: {
      project_id: project_id,
      tile_id: tile_id,
      idea: idea,
      description: description
    }
  });
  return response.data;
}

export const get_background_material = async ({ project_id, rec_type }) => {
  const response = await axiosInstance({
    url: "/project/idea/background_materials",
    method: "GET",
    params: {
      project_id: project_id,
      rec_type: rec_type
    }
  });
  return response.data;
}

export const find_calls = async ({ project_id, min_award_ceiling, min_award_floor, cost_sharing, closing_date, category_of_funding }) => {
  const response = await axiosInstance({
    url: "/process/idea/calls",
    method: "POST",
    data: {
      project_id: project_id,
      min_award_ceiling: min_award_ceiling,
      min_award_floor: min_award_floor,
      cost_sharing: cost_sharing,
      closing_date: closing_date,
      category_of_funding: category_of_funding
    }
  });
  return response.data;
}

export const get_user_pinned_calls = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/project/idea/calls",
    method: "GET",
    params: {
      project_id: project_id,
      user_pinned: true
    }
  });
  return response.data;
}

export const get_project_calls = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/project/idea/calls",
    method: "GET",
    params: {
      project_id: project_id,
      user_pinned: false
    }
  });
  return response.data;
}

export const get_all_calls = async () => {
  const response = await axiosInstance({
    url: "/project/idea/calls",
    method: "GET",
    params: {
      user_bookmarked: true
    }
  });
  return response.data;
}


export const get_papers = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/project/paper/info",
    method: "GET",
    params: {
      project_id: project_id,
    }
  });
  return response.data;
}

export const generate_key_topics = async ({ project_id, paper_type }) => {
  const response = await axiosInstance({
    url: "/process/idea/key_topics",
    method: "POST",
    data: {
      project_id: project_id,
      paper_type: paper_type
    }
  });
  return response.data;
}

export const add_key_topic = async ({ project_id, key_topic, paper_type }) => {
  const response = await axiosInstance({
    url: "/project/idea/key_topics",
    method: "POST",
    data: {
      project_id: project_id,
      key_topic: key_topic,
      paper_type: paper_type
    }
  });
  return response.data;
}

export const remove_key_topic = async ({ project_id, key_topic, paper_type }) => {
  const response = await axiosInstance({
    url: "/project/idea/key_topics",
    method: "DELETE",
    data: {
      project_id: project_id,
      key_topic: key_topic,
      paper_type: paper_type
    }
  });
  return response.data;
}

export const get_brainstorm = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/project/idea/brainstorm",
    method: "GET",
    params: {
      project_id: project_id
    }
  });
  return response.data;
}

/**
 * 
 * @param {*} project_id 
 * @param {*} brainstorm_ideas Array of ideas
 * @param {*} ai_generated Boolean
 * @returns 
 */
export const add_brainstorm_ideas = async ({ project_id, brainstorm_ideas, ai_generated }) => {
  const response = await axiosInstance({
    url: "/project/idea/brainstorm",
    method: "POST",
    data: {
      project_id: project_id,
      ideas: brainstorm_ideas,
      ai_generated: ai_generated,
    }
  });
  return response.data;
}

export const delete_brainstorm_idea = async ({ project_id, brainstorm_id }) => {
  const response = await axiosInstance({
    url: "/project/idea/brainstorm",
    method: "DELETE",
    data: {
      project_id: project_id,
      brainstorm_id: brainstorm_id
    }
  });
  return response.data;
}

export const update_brainstorm_idea = async ({ project_id, brainstorm_idea }) => {
  await axiosInstance({
    url: "/project/idea/brainstorm",
    method: "POST",
    data: {
      project_id: project_id,
      ideas: [brainstorm_idea],
      edit_ideas: true
    }
  });
  return brainstorm_idea;
}

export const generate_brainstorm_ideas = async ({ project_id }) => {
  const response = await axiosInstance({
    url: "/process/idea/brainstorm",
    method: "POST",
    data: {
      project_id: project_id
    }
  });
  return response.data;
}

