import { Input } from "@/components/styled/input";
import { Tooltip } from "@/components/styled/tooltip";
import { useState } from "react";

export default function UploadPaperViaLink({ projectId, trigger }) {
  const [link, setLink] = useState("");
  // const [domain, setDomain] = useState("other");

  // useEffect(() => {
  //   try {
  //     const { hostname } = new URL(link);
  //     console.log(hostname);
  //     switch (hostname) {
  //       case "www.arxiv.org":
  //       case "arxiv.org":
  //         setDomain("arxiv");
  //         break;
  //       case "www.biorxiv.org":
  //       case "biorxiv.org":
  //         setDomain("biorxiv");
  //         break;
  //       default:
  //         setDomain("other");
  //         break;
  //     }
  //   } catch (error) {
  //     setDomain("other");
  //     // console.log(error);
  //   }
  // }, [link]);

  const onUpload = () => {
    trigger({ project_id: projectId, link: link, upload_type: "link" });
  };

  return (
    <>
      <div>
        <Tooltip
          trigger={
            <span className="font-semibold">Insert Link to PDF</span>
          }
          content="Drop in a link to any relevant paper below. We currently only accept links to publicly available pdf files that are under 10MB in size."
        />
      </div>
      <div className="flex gap-x-2">
        <Input value={link} onChange={setLink} />
        {/* <Select className="border-2 rounded-md" value={domain} onChange={(e) => setDomain(e.target.value)}>
          <option value="arxiv">Arxiv</option>
          <option value="biorxiv">Biorxiv</option>
          <option value="pubmed">Pubmed</option>
          <option value="other">Other</option>
        </Select> */}
      </div>
      <div className="w-full">
        <button
          disabled={link.length === 0}
          className="btn-fuchsia"
          onClick={onUpload}
        >
          Upload
        </button>
      </div>
    </>
  );
}
