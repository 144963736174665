import { Input } from "@headlessui/react";
import { useState } from "react";

export default function EditableTitle({ className, value, setValue }) {
  const [inputValue, setInputValue] = useState(value);
  const [isEditting, setIsEditting] = useState(false);
  const handleOnClick = () => setIsEditting(true);
  const handleBlur = () => {
    setValue(inputValue);
    setIsEditting(false);
  };
  const handleKeyDown = (e) => {
    switch (e.key) {
      case "Enter":
        handleBlur();
        break;
      case "Escape":
        setInputValue(value);
        setIsEditting(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className="relative w-fit mb-2" onClick={handleOnClick}>
      <div className={"relative font-semibold z-10 pl-2 pr-4 " + className}>
        {isEditting ? (
          <Input
            value={inputValue}
            onKeyDown={handleKeyDown}
            onChange={(e) => setInputValue(e.target.value)}
            autoFocus={true}
            onBlur={handleBlur}
          />
        ) : (
          <p className="hover:ring-fuchsia hover:ring-2 rounded-md line-clamp-1">
            {value}
          </p>
        )}
      </div>
      <div className="absolute w-full rounded-3xl bg-pastel-fuchsia z-[1] top-1/3 bottom-0" />
    </div>
  );
}
