import { Transition } from "@headlessui/react"

export default function SlideUp({ show, children }) {

  return <Transition
    show={show}
    className="transition-all ease-linear duration-150"
    enterFrom="max-h-0 opacity-0"
    enterTo="max-h-full opacity-100"
    leaveFrom="max-h-full opacity-100"
    leaveTo="max-h-0 opacity-0"
  >
    {children}
  </Transition>
}