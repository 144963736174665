export default function MoreComingSoonCard() {
  return (
    <div
      className="flex items-center justify-center font-bold text-3xl p-4  text-opacity-0 bg-light-pink rounded-ss-3xl rounded-md"
    >
      More Spaces
      <span className="ml-2 underline">
        Coming Soon!
      </span>
    </div>
  );
}
