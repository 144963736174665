import { ErrorPage, LoadingPage, Title, Toggle } from "components";
import { Tooltip } from "@/components/styled/tooltip";
import { Input } from "@/components/styled/input";
import { Link, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import {
  get_project,
  get_profile,
  find_calls,
  get_project_calls,
  get_general,
} from "api";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import Select from "react-select";
import Call from "./Call";
import { format } from "date-fns";
import { DatePicker } from "@/components/ui/datepicker";
import { ScrollArea } from "@/components/ui/scroll-area";
import { PageHelp } from "@/components/styled/pageHelp";
import { Switch } from "@/components/ui/switch";

const fundingOptions = [
  { value: "ACA", label: "Affordable Care Act" },
  { value: "AG", label: "Agriculture" },
  { value: "AR", label: "Arts" },
  { value: "BC", label: "Business and Commerce" },
  { value: "CD", label: "Community Development" },
  { value: "CP", label: "Consumer Protection" },
  { value: "DPR", label: "Disaster Prevention and Relief" },
  { value: "ED", label: "Education" },
  { value: "ELT", label: "Employment, Labor and Training" },
  { value: "EN", label: "Energy" },
  { value: "ENV", label: "Environment" },
  { value: "FN", label: "Food and Nutrition" },
  { value: "HL", label: "Health" },
  { value: "HO", label: "Housing" },
  { value: "HU", label: "Humanities" },
  { value: "ISS", label: "Income Security and Social Services" },
  { value: "IS", label: "Information and Statistics" },
  { value: "LJL", label: "Law, Justice and Legal Services" },
  { value: "NR", label: "Natural Resources" },
  { value: "RA", label: "Recovery Act" },
  { value: "RD", label: "Regional Development" },
  {
    value: "ST",
    label: "Science and Technology and other Research and Development",
  },
  { value: "T", label: "Transportation" },
  { value: "O", label: "Other" },
];

export default function RecommendedCalls() {
  const { projectId } = useParams();

  const {
    data: project,
    isLoading: isProjectLoading,
    error: isProjectError,
  } = useSWR(`get_project/${projectId}`, () =>
    get_project({ project_id: projectId })
  );

  const {
    data: profile,
    isLoading: isProfileLoading,
    error: isProfileError,
  } = useSWR(`get_profile`, () => get_profile());

  const {
    data: calls,
    isLoading: isCallsLoading,
    error: isCallsError,
  } = useSWR(`get_project_calls/${projectId}`, async () => {
    const response = await get_project_calls({ project_id: projectId });
    if (typeof response === "string")
      return { recommendedCalls: [], bookmarkedCalls: [] };
    const { calls } = response;
    const recommendedCalls = calls.filter((c) => !c.bookmarked);
    const bookmarkedCalls = calls.filter((c) => c.bookmarked);
    if (recommendedCalls && recommendedCalls.length > 0) {
      setIsShowingResults(true);
    }
    return {
      recommendedCalls: recommendedCalls,
      bookmarkedCalls: bookmarkedCalls,
    };
  });

  const {
    data: researchIdea,
    isLoading: isResearchIdeaLoading,
    error: isResearchIdeaError,
  } = useSWR(`/project/idea/general/${projectId}`, () =>
    get_general({ project_id: projectId, tile_id: 4 })
  );

  const [isShowingResults, setIsShowingResults] = useState(
    calls?.recommendedCalls?.length > 0 || false
  );

  const {
    isMutating: isFindCallsLoading,
    error: isFindCallsError,
    trigger,
  } = useSWRMutation(
    `/process/idea/calls${projectId}`,
    () =>
      find_calls({
        project_id: projectId,
        min_award_ceiling: grantCeiling,
        min_award_floor: grantFloor,
        cost_sharing: costSharing,
        closing_date: closingDate,
        category_of_funding:
          fundingCategory.length === 0
            ? ["all"]
            : fundingCategory.map((c) => c.value),
      }),
    {
      onSuccess: (new_calls) => {
        setIsShowingResults(true);
        mutate(
          `get_project_calls/${projectId}`,
          (old_calls) => {
            if (typeof new_calls === "string") {
              return { ...old_calls, recommendedCalls: [] };
            } else {
              return { ...old_calls, recommendedCalls: new_calls };
            }
          },
          { revalidate: false }
        );
      },
    }
  );

  const [grantCeiling, setGrantCeiling] = useState(0);
  const [grantFloor, setGrantFloor] = useState(0);
  const [closingDate, setClosingDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [costSharing, setCostSharing] = useState(false);
  const [fundingCategory, setFundingCategory] = useState([]);

  const handleClosingDate = (newDate) => {
    const formattedDate = format(newDate, "yyyy-MM-dd");
    setClosingDate(formattedDate);
  };

  if (
    isCallsLoading ||
    isFindCallsLoading ||
    isResearchIdeaLoading ||
    isProjectLoading ||
    isProfileLoading
  )
    return <LoadingPage />;
  if (
    isCallsError ||
    isFindCallsError ||
    isResearchIdeaError ||
    isProjectError ||
    isProfileError
  )
    return <ErrorPage />;
  const hasEditorAccess =
    project?.users.find((p) => p.user_id === profile.user_id).relationship !==
    "viewer";

  return (
    <div className="bg-white grow rounded-lg h-full p-4">
      <div className="flex flex-col h-full gap-y-4">
        <div className="flex justify-between">
          <Title className="text-4xl" value="Recommended Calls" />
          <PageHelp
            title="Grant Space"
            content={
              <div className="flex flex-col gap-y-4 text-black">
                <div>
                  The Grant Space is dedicated to discovering and managing
                  funding opportunities.
                </div>

                <div>
                  <h2 className="font-semibold">Selected Call</h2>
                  View all details about the grant you are applying for. If you
                  haven't selected a call yet, you can do so here.
                </div>
                <div>
                  <h2 className="font-semibold">Recommend Calls</h2>
                  Using the information you provided in the Research Idea and
                  Background Material sections of the Ideation Space, we will
                  suggest funding opportunities we think may be relevant to you.
                </div>
                <div>
                  <h2 className="font-semibold">Search For Calls</h2>A search
                  tool for finding calls.
                </div>
                <div>
                  <h2 className="font-semibold">Bookmarked Calls</h2>
                  Keep track of important calls by bookmarking them. This
                  section shows all calls bookmarked by you and any other
                  collaborators on the project.
                </div>
              </div>
            }
          />
        </div>
        {!researchIdea?.idea || !researchIdea?.description ? (
          <div>
            Please fill out your{" "}
            <Link
              className="text-fuchsia hover:underline"
              to={`/project/${projectId}/ideation-space`}
            >
              research idea and description
            </Link>{" "}
            to get recommended calls.
          </div>
        ) : (
          <>
            {!isShowingResults && (
              <>
                <div className={"flex flex-wrap gap-x-4 gap-y-2"}>
                  <div className="flex flex-col w-40">
                    <div>
                      <Tooltip
                        trigger={
                          <span className="text-base underline">
                            Min. Award
                          </span>
                        }
                        content="We will only search for calls that have an award that is higher than the value you provide here."
                      />
                    </div>
                    <Input
                      className="w-full pl-1"
                      type="number"
                      value={grantFloor}
                      onChange={(v) => setGrantFloor(v)}
                    />
                  </div>

                  <div className="flex flex-col">
                    <span className="text-base underline">
                      Earliest Closing Date:
                    </span>
                    <DatePicker
                      value={closingDate}
                      onChange={handleClosingDate}
                    />
                  </div>
                  <div className="flex flex-col">
                    <Tooltip
                      trigger={
                        <span className="text-base underline">
                          Cost Sharing:
                        </span>
                      }
                      content="This filter indicates if you want to search for calls with a Cost Sharing or Matching requirement. If turned off, we will only search for ones without. If turned on, we will search for either."
                    />
                    <Switch
                      checked={costSharing}
                      onClick={() => {
                        setCostSharing((p) => !p);
                      }}
                    ></Switch>
                  </div>
                  <div className="flex flex-col w-full">
                    <div>
                      <Tooltip
                        trigger={
                          <div className="text-sm underline">
                            Funding Category:
                          </div>
                        }
                        content="This filter will only search for calls if they belong to any of the selected categories. Leave blank if you would like to search for any category."
                      />
                    </div>

                    <Select
                      className="min-w-12 p-0 text-sm border-input rounded-md border-[1px] cursor-default py-1"
                      menuPosition="fixed"
                      classNames={{
                        container: ({ isFocused }) =>
                          `${
                            isFocused
                              ? " ring-2 ring-offset-background ring-offset-2 ring-ring"
                              : ""
                          }`,
                      }}
                      styles={{
                        control: () => ({ display: "flex" }),
                      }}
                      closeMenuOnSelect={false}
                      isClearable
                      isMulti
                      onChange={(newValue) => setFundingCategory(newValue)}
                      options={fundingOptions}
                      value={fundingCategory}
                      placeholder={"Leave empty to search all categories"}
                    />
                  </div>
                </div>
                <div className="flex gap-x-2">
                  <button
                    className="btn-offblack"
                    onClick={trigger}
                    disabled={isFindCallsLoading}
                  >
                    <span>Search for relevant calls</span>
                  </button>
                  {calls?.recommendedCalls?.length > 0 && (
                    <button
                      className="underline hover:text-fuchsia"
                      onClick={() => setIsShowingResults(true)}
                    >
                      See current results
                    </button>
                  )}
                </div>
              </>
            )}

            {isShowingResults && (
              <>
                <div className="flex justify-center">
                  <button
                    className="btn-fuchsia text-base rounded-full"
                    onClick={() => setIsShowingResults(false)}
                  >
                    Run a new search
                  </button>
                </div>
                <ScrollArea className="p-4">
                  <div className="flex flex-col gap-y-8">
                    {calls?.recommendedCalls?.length > 0 ? (
                      calls.recommendedCalls.map((call) => (
                        <Call
                          {...call}
                          projectId={projectId}
                          key={call.call_id}
                        />
                      ))
                    ) : (
                      <span>
                        There are no recommended calls. Please run another
                        search.
                      </span>
                    )}
                  </div>
                </ScrollArea>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
