import {
  add_assigned_user,
  delete_action_item,
  edit_action_item,
  remove_assigned_user,
} from "api";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { Select as ShadcnSelect } from "@/components/styled/select";

import { format } from "date-fns";
import { DatePicker } from "@/components/ui/datepicker";

import Select, { components } from "react-select";
import { mutate, useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";
import { MdDelete } from "react-icons/md";
import { FaExpand } from "react-icons/fa";
import { Dialog } from "@/components/styled/dialog";
import { Input } from "@/components/styled/input";
import { Textarea } from "@/components/styled/textarea";
import { Switch } from "@/components/ui/switch";

const PRIORITY_LABELS = [
  { label: <span className="flex justify-center">-</span>, value: null },
  { label: <span className="flex justify-center">Low</span>, value: 1 },
  { label: <span className="flex justify-center">Med</span>, value: 2 },
  { label: <span className="flex justify-center">High</span>, value: 3 },
];

export default function ActionItem({
  action_item_id,
  assignee_info,
  completed,
  description,
  due_date,
  item,
  priority,
  notify,
  projectId,
  hasEditorAccess,
}) {
  const { cache } = useSWRConfig();

  const project = cache.get(`get_project/${projectId}`).data;

  const userOptions = project.users.map(({ user_id, user_name }) => ({
    value: user_id,
    label: user_name,
  }));
  const userValue =
    assignee_info?.map((user) => ({
      value: user.user_id,
      label: user.user_name,
    })) || [];

  const CustomMenu = (props) => {
    return (
      <components.Menu {...props} onClick={(e) => e.preventDefault()}>
        {props.children}
      </components.Menu>
    );
  };

  const { error: updateItemError, trigger: updateItem } = useSWRMutation(
    `update_item`,
    (key, { arg }) => {
      edit_action_item({
        project_id: projectId,
        action_item_id: action_item_id,
        action_item: arg,
      });
      return arg;
    },
    {
      onSuccess: (new_item) => {
        mutate(
          `get_action_items/${projectId}`,
          (d) =>
            d.map((action_item) =>
              action_item.action_item_id === action_item_id
                ? { ...action_item, item: new_item }
                : action_item
            ),
          { revalidate: false }
        );
      },
    }
  );

  const { error: updateDescriptionError, trigger: updateDescription } =
    useSWRMutation(
      `update_description`,
      (key, { arg }) => {
        if (arg === description) return arg;
        edit_action_item({
          project_id: projectId,
          action_item_id: action_item_id,
          description: arg,
        });
        return arg;
      },
      {
        onSuccess: (new_description) => {
          mutate(
            `get_action_items/${projectId}`,
            (d) =>
              d.map((action_item) =>
                action_item.action_item_id === action_item_id
                  ? { ...action_item, description: new_description }
                  : action_item
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: toggleCompleteError, trigger: toggleComplete } =
    useSWRMutation(
      `toggle_complete`,
      () =>
        edit_action_item({
          project_id: projectId,
          action_item_id: action_item_id,
          complete: !completed,
        }),
      {
        onSuccess: () => {
          mutate(
            `get_action_items/${projectId}`,
            (d) =>
              d.map((action_item) =>
                action_item.action_item_id === action_item_id
                  ? { ...action_item, completed: !completed }
                  : action_item
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: toggleNotifyUserError, trigger: toggleNotifyUser } =
    useSWRMutation(
      `toggle_notify_user`,
      () =>
        edit_action_item({
          project_id: projectId,
          action_item_id: action_item_id,
          notify: !notify,
        }),
      {
        onSuccess: () => {
          mutate(
            `get_action_items/${projectId}`,
            (d) =>
              d.map((action_item) =>
                action_item.action_item_id === action_item_id
                  ? { ...action_item, notify: !notify }
                  : action_item
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: updatePriorityError, trigger: updatePriority } =
    useSWRMutation(
      `update_priority`,
      (key, { arg }) => {
        const value = arg;
        edit_action_item({
          project_id: projectId,
          action_item_id: action_item_id,
          priority: value,
        });
        return value;
      },
      {
        onSuccess: (priority) => {
          mutate(
            `get_action_items/${projectId}`,
            (d) =>
              d.map((action_item) =>
                action_item.action_item_id === action_item_id
                  ? { ...action_item, priority: priority }
                  : action_item
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: updateDueDateError, trigger: updateDueDate } = useSWRMutation(
    `update_due_date`,
    (key, { arg }) => {
      edit_action_item({
        project_id: projectId,
        action_item_id: action_item_id,
        due_date: arg,
      });
      return arg;
    },
    {
      onSuccess: (new_due_date) => {
        mutate(
          `get_action_items/${projectId}`,
          (d) =>
            d.map((action_item) =>
              action_item.action_item_id === action_item_id
                ? { ...action_item, due_date: new_due_date }
                : action_item
            ),
          { revalidate: false }
        );
      },
    }
  );

  const { error: addAssignedUserError, trigger: addAssignedUser } =
    useSWRMutation(
      `add_assigned_user`,
      async (_, { arg }) => {
        const { user_id } = arg;
        await add_assigned_user({
          project_id: projectId,
          action_item_id: action_item_id,
          assignee_uids: [user_id],
        });
        return arg;
      },
      {
        onSuccess: ({ user_id, user_name }) => {
          mutate(
            `get_action_items/${projectId}`,
            (d) =>
              d.map((action_item) =>
                action_item.action_item_id === action_item_id
                  ? {
                      ...action_item,
                      assignee_info: action_item.assignee_info
                        ? [
                            ...action_item.assignee_info,
                            { user_id: user_id, user_name: user_name },
                          ]
                        : [{ user_id: user_id, user_name: user_name }],
                    }
                  : action_item
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: removeAssignedUserError, trigger: removeAssignedUser } =
    useSWRMutation(
      `remove_assigned_user`,
      async (_, { arg }) => {
        const { user_id } = arg;
        await remove_assigned_user({
          project_id: projectId,
          action_item_id: action_item_id,
          assignee_uids: [user_id],
        });
        return arg;
      },
      {
        onSuccess: ({ user_id }) => {
          mutate(
            `get_action_items/${projectId}`,
            (d) =>
              d.map((action_item) =>
                action_item.action_item_id === action_item_id
                  ? {
                      ...action_item,
                      assignee_info: [
                        ...action_item.assignee_info.filter(
                          (u) => u.user_id !== user_id
                        ),
                      ],
                    }
                  : action_item
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: deleteActionItemError, trigger: deleteActionItem } =
    useSWRMutation(
      `update_item`,
      () =>
        delete_action_item({
          project_id: projectId,
          action_item_id: action_item_id,
        }),
      {
        onSuccess: () => {
          mutate(
            `get_action_items/${projectId}`,
            (d) =>
              d.filter(
                (action_item) => action_item.action_item_id !== action_item_id
              ),
            { revalidate: false }
          );
        },
      }
    );

  const onUpdateItem = (newItem) => {
    if (newItem !== item) updateItem(newItem);
  };

  const onChangeDate = (newDate) => {
    const formattedDate = format(newDate, "yyyy-MM-dd");
    updateDueDate(formattedDate);
  };

  const onChangeAssignedUsers = (_, actionType) => {
    if (actionType.action === "select-option") {
      const { value: user_id, label: user_name } = actionType.option;
      addAssignedUser({ user_id: user_id, user_name: user_name });
    } else if (actionType.action === "remove-value") {
      const { value: user_id } = actionType.removedValue;
      removeAssignedUser({ user_id: user_id });
    }
  };

  return (
    <TableRow key={action_item_id} className="group">
      <TableCell className="text-center">
        <Checkbox
          checked={completed}
          onClick={toggleComplete}
          disabled={!hasEditorAccess}
        />
      </TableCell>
      <TableCell className="relative">
        {/* {item} */}
        <Input
          readOnly={!hasEditorAccess}
          placeholder={"New task item"}
          value={item || ""}
          onChange={onUpdateItem}
          maxlength={250}
        />

        <Dialog
          className="absolute top-2 h-8 right-0 text-sm font-bold invisible group-hover:visible bg-light-grey hover:opacity-80 rounded-md px-1 flex items-center gap-x-1"
          trigger={
            <>
              <FaExpand /> OPEN
            </>
          }
          contentClassName="max-w-2xl"
          content={
            <div className="flex flex-col gap-y-4 text-black">
              <Input
                readOnly={!hasEditorAccess}
                placeholder={"New task item"}
                value={item || ""}
                onChange={onUpdateItem}
                maxlength={250}
              />

              <div className="flex gap-x-2 items-center">
                Complete
                <Checkbox
                  checked={completed}
                  onClick={toggleComplete}
                  disabled={!hasEditorAccess}
                />
              </div>
              <div className="flex gap-x-4">
                <div className="flex flex-col w-32">
                  Set Reminder
                  <Switch
                    checked={notify}
                    onClick={toggleNotifyUser}
                    disabled={!hasEditorAccess}
                  />
                </div>
                <div className="flex flex-col grow">
                  Due Date
                  <DatePicker value={due_date} onChange={onChangeDate} />
                </div>
              </div>

              <div className="flex gap-x-4">
                <div className="flex flex-col w-32">
                  Priority
                  <ShadcnSelect
                    value={priority}
                    options={PRIORITY_LABELS.map(({ label, value }) => ({
                      label: label,
                      value: value,
                    }))}
                    onChange={(v) => updatePriority(v)}
                  />
                </div>
                <div className="flex flex-col grow">
                  Assigned
                  <Select
                    isDisabled={!hasEditorAccess}
                    className="min-w-12 p-0 text-sm border-input rounded-md border-[1px] cursor-default py-1"
                    classNames={{
                      container: ({ isFocused }) =>
                        `${
                          isFocused
                            ? " ring-2 ring-offset-background ring-offset-2 ring-ring"
                            : ""
                        }`,
                    }}
                    styles={{
                      control: () => {},
                    }}
                    placeholder={"Assign a user"}
                    closeMenuOnSelect={false}
                    isSearchable={false}
                    noOptionsMessage={() => "No other users"}
                    options={userOptions}
                    isMulti={true}
                    isClearable={false}
                    onChange={onChangeAssignedUsers}
                    value={userValue}
                    components={{
                      Menu: CustomMenu,
                      DropdownIndicator: null,
                      ClearIndicator: null,
                    }}
                  />
                </div>
              </div>

              {/* <table className="table-auto text-base border-separate border-spacing-x-2 my-2">
                <tbody>
                  <tr>
                    <td className="font-semibold">Completed</td>
                    <td className="px-2">
                      <Checkbox
                        checked={completed}
                        onClick={toggleComplete}
                        disabled={!hasEditorAccess}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="font-semibold">Set Reminder</td>
                    <td className="px-2">
                      <Checkbox
                        checked={notify}
                        onClick={toggleNotifyUser}
                        disabled={!hasEditorAccess}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="font-semibold">Due Date</td>
                    <td className="px-2">
                      <DatePicker value={due_date} onChange={onChangeDate} />
                    </td>
                  </tr>
                  <tr>
                    <td className="font-semibold">Assigned</td>
                    <td className="px-2">
                      <Select
                        isDisabled={!hasEditorAccess}
                        className="min-w-12 p-0 text-sm border-input rounded-md border-[1px] cursor-default py-1"
                        classNames={{
                          container: ({ isFocused }) =>
                            `${
                              isFocused
                                ? " ring-2 ring-offset-background ring-offset-2 ring-ring"
                                : ""
                            }`,
                        }}
                        styles={{
                          control: () => {},
                        }}
                        placeholder={"Assign a user"}
                        closeMenuOnSelect={false}
                        isSearchable={false}
                        noOptionsMessage={() => "No other users"}
                        options={userOptions}
                        isMulti={true}
                        isClearable={false}
                        onChange={onChangeAssignedUsers}
                        value={userValue}
                        components={{
                          Menu: CustomMenu,
                          DropdownIndicator: null,
                          ClearIndicator: null,
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="font-semibold">Priority</td>
                    <td className="px-2">
                      <ShadcnSelect
                        value={priority}
                        options={PRIORITY_LABELS.map(({ label, value }) => ({
                          label: label,
                          value: value,
                        }))}
                        onChange={(v) => updatePriority(v)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table> */}

              <Textarea
                placeholder={"Enter a description..."}
                value={description || ""}
                onChange={updateDescription}
                readOnly={!hasEditorAccess}
              />
              <button
                onClick={deleteActionItem}
                className="text-red-500 flex items-center mt-8"
                disabled={!hasEditorAccess}
              >
                <MdDelete /> Delete
              </button>
            </div>
          }
        />
      </TableCell>
      <TableCell>
        {assignee_info?.map(({ user_name }) => user_name).join(", ")}
      </TableCell>

      <TableCell>
        {
          <ShadcnSelect
            value={priority}
            options={PRIORITY_LABELS.map(({ label, value }) => ({
              label: label,
              value: value,
            }))}
            onChange={(v) => updatePriority(v)}
          />
        }
      </TableCell>
      <TableCell>
        {<DatePicker value={due_date} onChange={onChangeDate} />}
      </TableCell>
    </TableRow>
  );
}
