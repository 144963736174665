import { unbookmark_project_call, unbookmark_user_call } from "api";
import { LoadingSpinner } from "components";
import { FaExternalLinkAlt, FaRegBookmark } from "react-icons/fa";
import { Link } from "react-router-dom";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function Call({
  agency,
  project_id,
  project_name,
  call_id,
  close_date,
  posted_date,
  title,
  url,
}) {
  const {
    isMutating: isUnbookmarking,
    error: unbookmarkError,
    trigger: unbookmarkCall,
  } = useSWRMutation(
    `unbookmark_user_call`,
    () =>
      project_id
        ? unbookmark_project_call({ project_id: project_id, call_id: call_id })
        : unbookmark_user_call({
            call_id: call_id,
          }),
    {
      onSuccess: () => {
        mutate((key) => key?.startsWith("search_calls"), undefined, {
          revalidate: true,
        });
        mutate("get_user_bookmarked_calls", undefined, { revalidate: true });
      },
    }
  );

  return (
    <>
      <div className="flex flex-col border-[1px] rounded-lg p-4 gap-x-4 hover:border-fuchsia text-left">
        <div>
          {project_name ? (
            <Link
              className="hover:text-fuchsia hover:underline"
              to={`/project/${project_id}`}
              onClick={(e) => e.stopPropagation()}
            >
              {project_name}
            </Link>
          ) : (
            <span>No Attached Project</span>
          )}
        </div>
        <div>
          {url ? (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
              className="text-2xl hover:text-fuchsia hover:underline font-semibold"
            >
              {title}{" "}
              <span className="inline-flex">
                <FaExternalLinkAlt />
              </span>
            </a>
          ) : (
            <span className="text-2xl font-semibold">{title}</span>
          )}
        </div>
        <span>{agency}</span>

        <div>
          <button
            className="flex justify-center items-center hover:text-fuchsia hover:underline text-base"
            disabled={isUnbookmarking}
            onClick={unbookmarkCall}
          >
            <FaRegBookmark /> Unbookmark{" "}
            {isUnbookmarking && <LoadingSpinner className="h-full" />}
          </button>
        </div>
        {unbookmarkError && (
          <span className="text-red-500 text-base">
            There was an issue unbookmarking this call. Please try again.
          </span>
        )}
      </div>
    </>
  );
}
