import { IoSearch } from "react-icons/io5";
import { useRef, useState } from "react";

export default function SearchBar({onSubmit}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [query, setQuery] = useState("");
  const inputRef = useRef();
  const localSubmit = (e) => {
    e.preventDefault();
    setSearchQuery(query);
    onSubmit(query);
  }
  return (
    <form onClick={()=>inputRef.current.focus()} onSubmit={localSubmit} className="group flex items-center border-2 rounded-full px-4 gap-x-2 has-[:focus]:border-fuchsia has-[:disabled]:border-light-grey " >
      <input ref={inputRef} onChange={(e) => { setQuery(e.target.value) }} className="w-full px-2 outline-none" />
      <button onClick={(e)=>e.stopPropagation()} className="" >
        <IoSearch className="text-light-grey hover:text-fuchsia hover:opacity-50 group-has-[:focus]:text-fuchsia" />
      </button>
    </form>
  )
}