import { Link } from "react-router-dom";

export default function Error404() {
  return (
  <div className="bg-white grow rounded-lg h-screen w-screen flex flex-col justify-center items-center">
    <div className="flex gap-x-2 items-center max-w-screen-md">
      <span className="text-4xl">Sorry! The page you were looking for cannot be found.</span>
      <span></span>
    </div>
    <div>Return to <Link className="text-fuchsia hover:underline" to={"/"}>home page</Link>.</div>
  </div>);
}
