import { delete_brainstorm_idea, update_brainstorm_idea } from "api";
import { MdDelete } from "react-icons/md";

import useSWRMutation from 'swr/mutation';
import { mutate } from "swr";
import { LoadingSpinner } from "components";
import EditableField from "components/EditableField";
import EditableTextInput from "components/EditableTextInput";


export default function TopicIdea({ projectId, brainstorm_id, ai_generated, description, item, index, hasEditorAccess }) {
  const { isMutating: isDeleting, error: isDeleteError, trigger } = useSWRMutation(
    `delete_brainstorm_idea`,
    () => { delete_brainstorm_idea({ project_id: projectId, brainstorm_id: brainstorm_id }) },
    {
      onSuccess: () => {
        mutate(`/project/idea/brainstorm/${projectId}`,
          data => { return { ...data, items: data.items.filter(d => d.brainstorm_id !== brainstorm_id) } },
          { revalidate: false }
        );
      }
    }
  );

  const { error: isTitleUpdateError, trigger: triggerTitleUpdate } = useSWRMutation(
    `update_brainstorm_idea`,
    (key, { arg }) => update_brainstorm_idea({ project_id: projectId, brainstorm_idea: { brainstorm_id: brainstorm_id, description: description, item: arg.item } }),
    {
      onSuccess: (brainstorm_idea) => {
        const newItem = brainstorm_idea.item;
        mutate(`/project/idea/brainstorm/${projectId}`,
          (data) => {
            const { items } = data;
            return { items: items.map(d => (d.brainstorm_id === brainstorm_id ? { ...d, item: newItem } : { ...d })) }
          },
          { revalidate: false }
        );
      }
    }
  );

  const { error: isDescriptionUpdateError, trigger: triggerDescriptionUpdate } = useSWRMutation(
    `update_brainstorm_idea`,
    (key, { arg }) => update_brainstorm_idea({ project_id: projectId, brainstorm_idea: { brainstorm_id: brainstorm_id, description: arg.newDescription, item: item } }),
    {
      onSuccess: (brainstorm_idea) => {
        const newDescription = brainstorm_idea.description;
        mutate(`/project/idea/brainstorm/${projectId}`,
          (data) => {
            const { items } = data;
            return { items: items.map(d => (d.brainstorm_id === brainstorm_id ? { ...d, description: newDescription } : { ...d })) }
          },
          { revalidate: false }
        );
      }
    }
  );

  const handleDescriptionUpdate = async (newDescription) => {
    if (newDescription !== description) {
      triggerDescriptionUpdate({ newDescription });
    }
  }

  const handleTitleUpdate = async (newItem) => {
    if (item !== newItem) {
      triggerTitleUpdate({ item: newItem });
    }
  }

  return (
    <>
      <div className="flex flex-col gap-y-2 pr-4 group p-1">
        <div className="flex text-2xl font-semibold items-center ">
          <span className="self-start whitespace-nowrap">{index}. </span>
          {/* {ai_generated
            ? <span className="text-base self-start text-white bg-fuchsia p-0.5 rounded-md leading-none">AI</span>
            : <span className="text-base self-start text-white p-2.5 rounded-md leading-none"></span>
          } */}
          <EditableTextInput className={"w-fit ml-2"} value={item} setValue={handleTitleUpdate} readOnly={!hasEditorAccess} />
          {hasEditorAccess && <button className="opacity-0 group-hover:opacity-100 hover:text-fuchsia" onClick={trigger}><MdDelete /></button>}

          <span className="bg-fuchsia grow ml-2 h-1 shrink"></span>
        </div>

        {(isDescriptionUpdateError || isTitleUpdateError) && <div className="text-red-500">There was an issue updating this idea. Please try again.</div>}
        {isDeleteError && <div className="text-red-500">There was an issue deleting this idea. Please try again.</div>}
        {isDeleting ?
          <div className="flex justify-center">
            <LoadingSpinner className="text-fuchsia h-40 w-40" />
            Deleting idea...
          </div>
          :
          <EditableField className="h-fit" value={description} setValue={handleDescriptionUpdate} readOnly={!hasEditorAccess} />
        }

      </div>
    </>
  );
}
