import { FaList } from "react-icons/fa6";

import { Link } from "react-router-dom";

export default function AdminCard({ projectId }) {
  const link = `/project/${projectId}/admin-space`;
  return (
    <div className="relative bg-zinc-600 rounded-3xl m-0.5 w-fit h-fit">
      <Link
        to={link}
        className="w-60 h-60 relative -m-0.5 hover:-translate-x-2 hover:-translate-y-2 transition-transform bg-zinc-200 rounded-3xl p-3 flex flex-col group"
      >
        <div className="flex justify-between items-center gap-x-2">
          <div className="flex items-center gap-x-2 ">
            <FaList className="w-8 h-full" />
          </div>
        </div>
        <div className="self-center my-auto font-semibold text-3xl group-hover:text-fuchsia group-hover:underline text-clip overflow-hidden line-clamp-4">
          <span>Admin Space</span>
        </div>
      </Link>
    </div>
  );
}
