import { ReactComponent as InitiumIcon } from "assets/initium-logos/brandmark-colored.svg";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { useState } from "react";

export default function InitiumChatMessage({ agency_name, text, references }) {
  const [isReferencesOpen, setIsReferencesOpen] = useState(false);
  const tabs = references?.map(({ id }, i) => (
    <Tab
      className="data-[selected]:bg-fuchsia bg-offblack data-[hover]:opacity-50 text-white rounded-md px-2"
      key={id}
    >
      {i + 1}
    </Tab>
  ));
  const panels = references?.map(({ text, id }) => (
    <TabPanel key={id}>{text}</TabPanel>
  ));

  return (
    <div className="w-2/3 flex gap-x-2 items-end">
      <div className="rounded-full bg-pastel-blue border-2 h-fit w-fit translate-y-1/2">
        <InitiumIcon className="w-8 h-8" />
      </div>
      <div className="bg-pastel-blue flex flex-col px-4 py-1 rounded-2xl rounded-es-none text-sm">
        <div className="border-b-1 border-offblack font-semibold text-base">
          {agency_name} Agency Guideline
        </div>
        <div>{text}</div>
        {references && (
          <>
            <div className="flex justify-end">
              <button
                className="underline text-base hover:text-fuchsia"
                onClick={() => setIsReferencesOpen((p) => !p)}
              >
                &#91;References&#93;
              </button>
            </div>
            {isReferencesOpen && (
              <div className="border-t-2 border-offblack">
                <TabGroup>
                  <TabList className="gap-x-2 flex py-2">{tabs}</TabList>
                  <TabPanels>{panels}</TabPanels>
                </TabGroup>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
