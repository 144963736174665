import { ScrollArea } from "@/components/ui/scroll-area";
import SelectedPaper from "./SelectedPaper";
import { get_papers } from "api";
import { ErrorPage, LoadingPage } from "components";
import useSWR from "swr";

export default function SelectedPapersTab({ projectId }) {
  const {
    data: papers,
    isLoading: isPapersLoading,
    error: isPapersError,
    mutate: mutatePapers,
  } = useSWR(`get_papers?${projectId}`, () =>
    get_papers({ project_id: projectId })
  );

  if (isPapersLoading) return <LoadingPage />;
  if (isPapersError) return <ErrorPage />;

  return papers ? (
    <ScrollArea className="p-4 mb-8">
      <div className="flex flex-col gap-y-8">
        {papers
          ?.toSorted((a, b) => a.paper_id - b.paper_id)
          .map((paper) => (
            <SelectedPaper
              mutatePapers={mutatePapers}
              key={paper.paper_id}
              projectId={projectId}
              {...paper}
              title={paper.name}
            />
          ))}
      </div>
    </ScrollArea>
  ) : (
    <div className="border-dashed border-4 rounded-md flex flex-col justify-center items-center grow my-2">
      <div className="font-semibold">
        You haven't added any papers to the project yet.
      </div>
    </div>
  );
}
