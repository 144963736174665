import axiosInstance from "api/axiosInstance";
import { useAuthStore } from "stores/useAuthStore";

export const remove = async ({ email }) => {
  return await axiosInstance({
    url: "/user/remove",
    method: "DELETE",
    data: {
      email: email
    }
  })
}

export const ping = async () => {
  try {
    const response = await axiosInstance({ url: "/ping" });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const refresh_token = async ({ refreshToken }) => {
  const response = await axiosInstance({
    url: "/auth/refresh",
    method: "POST",
    headers: {
      "X-INITIUM-REFRESH": refreshToken,
    },
  });
  return response.data;
};

/**
 *
 * @param {*} username  required
 * @param {*} password required
 * @param {*} passwordConfirm required
 * @param {*} email required
 * @returns
 */
export const register = async ({
  username,
  password,
  password_confirm,
  email,
}) => {
  if (!username || !password || password !== password_confirm || !email)
    throw new Error("Form is incorrect");

  const response = await axiosInstance({
    url: "/user/signup",
    method: "POST",
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
      "X-INITIUM-CLIENT-PASSWORD": password,
      "X-INITIUM-CLIENT-PASSWORD-CONFIRM": password_confirm,
    },
    data: {
      user_name: username,
      email: email,
    },
  });
  return response.data;
};

/**
 *
 * @param {string} verification_code required
 * @param {boolean} resend required
 * @param {string} email required
 * @returns
 */
export const verifyUser = async ({ verification_code, resend, email }) => {
  const response = await axiosInstance({
    url: "/user/verify_user",
    method: "POST",
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      verification_code: verification_code,
      resend: resend,
      email: email,
    },
  });
  return response.data;
};

/**
 * Required to send either { reset_code, password, password_confirm} or {send_email, email}
 * Either 1. Sends email to reset password or 2. Reset password with a reset_code
 * @param {*} reset_code Optional: A reset code to reset the users password
 * @param {*} password Optional: Given when a reset_code is provided. New password
 * @param {*} password_confirm Optional: Given when a reset_code is provided. New password that must match
 * @param {*} send_email Optional: Boolean to indicate if we are sending the email or resetting password. Overrides reset_code
 * @param {*} email Optional: The email to email with the new password code
 */
export const reset_password = async ({
  reset_code,
  password,
  password_confirm,
  send_email,
  email,
}) => {
  const response = await axiosInstance({
    url: "/user/reset_password",
    method: "POST",
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
      "X-INITIUM-CLIENT-PASSWORD": password,
      "X-INITIUM-CLIENT-PASSWORD-CONFIRM": password_confirm,
    },
    data: {
      reset_code: reset_code,
      send_email: send_email,
      email: email,
    },
  });
  return response.data;
};

export const deactivate = async () => {
  const response = await axiosInstance({
    url: "/user/deactivate",
    method: "POST",
    responseType: "json",
  });
  return response.data;
};

export const logout = async () => {
  useAuthStore.persist.clearStorage();
  const response = await axiosInstance({
    url: "/user/logout",
    method: "DELETE",
  });
  return response.data;
};

export const googleLogin = async ({ tokenId }) => {
  const response = await axiosInstance({
    url: "/user/google_login",
    method: "POST",
    responseType: "json",
    data: {
      tokenId: tokenId,
    },
  });
  return response.data;
};

export const login = async ({ email, password }) => {
  const response = await axiosInstance({
    url: "/user/login",
    method: "POST",
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
      "X-INITIUM-CLIENT-PASSWORD": password,
    },
    data: {
      email: email,
    },
  });
  return response.data;
};
