import { create } from "zustand";
import { persist } from "zustand/middleware";


// Listens for updates to localStorage and keeps zustand's storage up to date.
// Prevents issue of when another tab updates localStorage and the current tab is now out of date.
export const withStorageDOMEvents = (store) => {
  const storageEventCallback = (e) => {
    if (e.key === store.persist.getOptions().name && e.newValue) {
      store.persist.rehydrate();
    }
  };

  window.addEventListener("storage", storageEventCallback);

  return () => {
    window.removeEventListener("storage", storageEventCallback);
  };
};

export const useAuthStore = create(
  persist(
    (set) => ({
      refreshToken: null,
      setRefreshToken: (newRefreshToken) => set((state) => ({ ...state, refreshToken: newRefreshToken })),
      logout: () => set((state) => ({ ...state, refreshToken: null }))
    }),
    {
      name: "auth-store",
      partialize: (state) => ({ refreshToken: state.refreshToken }),
    }
  )
);

withStorageDOMEvents(useAuthStore);