import { Textarea } from "@headlessui/react";
import { useRef, useState } from "react";

export default function EditableField({
  className,
  value,
  setValue,
  placeholder,
  readOnly,
  alwaysEditing = false,
}) {
  const [inputValue, setInputValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const fieldRef = useRef(null);
  const contentRef = useRef(null);

  const handleOnClick = () => setIsEditing(true);
  const handleBlur = () => {
    setValue(inputValue);
    setIsEditing(false);
  };
  const handleKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === "Enter" && !e.shiftKey) {
      handleBlur();
      e.preventDefault();
      e.currentTarget.blur();
    } else if (e.key === "Escape") {
      setInputValue(value);
      setIsEditing(false);
      e.currentTarget.blur();
    }
  };

  return readOnly ? (
    <div className={"rounded-md " + className}>
      {value || <span className="opacity-50">{placeholder}</span>}
    </div>
  ) : (
    <>
      {(isEditing || alwaysEditing) && (
        <Textarea
          ref={fieldRef}
          aria-hidden={!(isEditing || alwaysEditing)}
          style={{
            height:
              fieldRef.current?.scrollHeight ||
              contentRef.current?.scrollHeight,
          }}
          className={"w-full aria-hidden:hidden " + className}
          placeholder={placeholder}
          value={inputValue}
          onKeyDown={handleKeyDown}
          onChange={(e) => setInputValue(e.target.value)}
          autoFocus={!alwaysEditing}
          onBlur={handleBlur}
        />
      )}
      <div
        ref={contentRef}
        aria-hidden={isEditing || alwaysEditing}
        onClick={handleOnClick}
        className={
          "hover:ring-fuchsia hover:ring-2 aria-hidden:hidden rounded-md " +
          className
        }
      >
        {value || <span className="opacity-50">{placeholder}</span>}
      </div>
    </>
  );
}
