import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { FaQuestionCircle } from "react-icons/fa";

function p({ title, content }) {
  return (
    <Sheet>
      <SheetTrigger className="hover:text-fuchsia">
        <FaQuestionCircle />
        {/* <FaFileCircleQuestion /> */}
        {/* <BsFillQuestionDiamondFill /> */}
        {/* <BsFillQuestionSquareFill /> */}
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
          <SheetDescription>{content}</SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
}
export { p as PageHelp };
