import { reset_password } from "api/auth";
import { LoadingSpinner, TextInput } from "components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWRMutation from "swr/mutation";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const { isMutating, error, trigger: sendRecoveryEmail, data } =
  useSWRMutation(
    `reset_password`,
    () => reset_password({ send_email: true, email: email }),
    {
      onSuccess: (data) => {
      }
    }
  );

  return (
    <div className="flex justify-center">
      <div className="max-w-lg w-full h-full bg-gradient-to-br p-1 rounded-2xl from-fuchsia to-blue">
        <div className="text-center py-4 text-2xl font-bold bg-offblack text-white rounded-t-2xl">
          Initium.AI
        </div>
        <div className="text-center py-10 font-bold bg-white rounded-b-2xl flex flex-col gap-y-4 px-4 justify-center items-center">
          Forgot Password
          {isMutating ? <LoadingSpinner className="text-fuchsia h-20 w-20" /> :
            (data && !error ? <span>Email has been sent, please check your email!</span>
              :
              <form className="flex flex-col gap-y-4 w-full" onSubmit={sendRecoveryEmail}>
                <TextInput placeholder="Email" input={email} defaultValue={""} setInput={setEmail} />
                <button disabled={!email.length} className="btn-fuchsia ">Send Recovery Email</button>
                {error && <span className="text-red-500">Something went wrong, please try again.</span>}
              </form>
            )
          }

          <div className="flex flex-col items-center justify-center">
            <button
              className="text-base link-pink"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </button>
            <button
              className="text-base link-pink"
              onClick={() => {
                navigate("/register");
              }}
            >
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
