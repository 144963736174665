import { get_background_material, get_profile } from "api";
import { ErrorPage, LoadingPage } from "components";
import { Tooltip } from "@/components/styled/tooltip";
import useSWR from "swr";

import Suggestion from "./Suggestion";
import KeyTopics from "./KeyTopics";
import { Select } from "@/components/styled/select";
import { Input } from "@/components/styled/input";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { search_papers } from "api";
import { LoadingSpinner } from "components";
import { ScrollArea } from "@/components/ui/scroll-area";

export default function FindPapersTab({ projectId }) {
  const currentYear = new Date().getFullYear();
  const minYear = 1900;
  const [yearFrom, setYearFrom] = useState(2000);
  const [yearTo, setYearTo] = useState(currentYear);

  const [recType, setRecType] = useState("all");

  const {
    data: generalInfo,
    isLoading: isBackgroundLoading,
    error: isBackgroundError,
    mutate: mutateGeneralInfo,
  } = useSWR(
    `/project/idea/background_materials/${projectId}?rec_type=${recType}`,
    () => get_background_material({ project_id: projectId, rec_type: recType }),
    {
      keepPreviousData: true,
      onSuccess: (d) => {
        if (d.papers) {
          setSearchEngine(d.papers.engine);
        }
        if (d.papers?.papers?.length > 0) {
          setIsShowingResults(true);
        }
      },
    }
  );
  const [isShowingResults, setIsShowingResults] = useState(
    generalInfo?.papers?.papers?.length > 0 || false
  );

  // ax = arxiv, ss = semantic scholar
  const [searchEngine, setSearchEngine] = useState(
    generalInfo?.papers?.engine || "ax"
  );

  const {
    data: profile,
    isLoading: isProfileLoading,
    error: isProfileError,
  } = useSWR(`get_profile`, () => get_profile());

  const {
    isMutating: searchingPapers,
    error: searchPapersError,
    trigger: searchPapers,
  } = useSWRMutation(
    `search_papers/${projectId}`,
    (key, { arg }) =>
      search_papers({
        project_id: projectId,
        oldest_year: yearFrom,
        newest_year: yearTo,
        engine: searchEngine,
        team: recType !== "all",
        authors:
          recType === "all" || searchEngine === "ss"
            ? null
            : [profile.research_name],
      }),
    {
      onSuccess: (data) => {
        mutateGeneralInfo((d) => ({ ...d, papers: data }), {
          revalidate: false,
        });
      },
    }
  );

  const onSearch = () => {
    searchPapers();
    setIsShowingResults(true);
  };

  if (isBackgroundLoading || isProfileLoading) return <LoadingPage />;
  if (isBackgroundError || isProfileError) return <ErrorPage />;

  return (
    <div className="flex flex-col gap-y-2 w-full h-full overflow-y-auto p-1">
      {!isShowingResults && (
        <>
          <div className="">
            <KeyTopics
              key_topics={generalInfo?.key_topics || []}
              projectId={projectId}
              recType={recType}
            />
          </div>

          <div className={"flex flex-wrap gap-x-4"}>
            <div className="w-40">
              <div className="text-base underline">Year From:</div>
              <Input
                type="number"
                value={yearFrom}
                onChange={(v) => setYearFrom(v)}
                min={minYear}
                max={currentYear}
                className="border-2 border-zinc-200 rounded-md"
              />
            </div>
            <div className="w-40">
              <div className="text-base underline">Year To:</div>
              <Input
                type="number"
                value={yearTo}
                onChange={(v) => setYearTo(v)}
                min={minYear}
                max={currentYear}
                className="border-2 border-zinc-200 rounded-md"
              />
            </div>
            <div className="w-40">
              <div className="text-base underline">Search On:</div>
              <Select
                value={searchEngine}
                onChange={(v) => setSearchEngine(v)}
                options={[
                  { value: "ax", label: "Arxiv" },
                  { value: "ss", label: "Semantic Scholar" },
                ]}
              />
            </div>
            {searchEngine !== "ss" && (
              <div className="w-40">
                <Tooltip
                  trigger={<div className="text-base underline">Authors:</div>}
                  content="“Only Team Members” will allow you to search for papers that were written by you or the other collaborators on this project. “Anyone” will search for papers written by anyone."
                />
                <Select
                  value={recType}
                  onChange={(v) => setRecType(v)}
                  options={[
                    { value: "all", label: "Anyone" },
                    { value: "team", label: "Only Team Members" },
                  ]}
                />
              </div>
            )}
          </div>
          <div className="flex gap-x-2 items-baseline">
            <button className="btn-offblack" onClick={onSearch}>
              Search for Papers
            </button>
            {generalInfo?.papers?.papers?.length > 0 && (
              <div>
                <button
                  className="underline hover:text-fuchsia"
                  onClick={() => setIsShowingResults(true)}
                >
                  See current results
                </button>
              </div>
            )}
          </div>
        </>
      )}

      {searchingPapers && (
        <div className="flex flex-col justify-center items-center h-full">
          <LoadingSpinner className="text-fuchsia h-40 w-40" />
          <span className="text-3xl font-semibold">Searching Papers</span>
        </div>
      )}
      {!searchingPapers && isShowingResults && (
        <>
          <div className="flex justify-center">
            <button
              className="rounded-full bg-fuchsia text-white px-4 py-1 text-base hover:opacity-70"
              onClick={() => setIsShowingResults(false)}
            >
              Run a new search
            </button>
          </div>

          <ScrollArea className="p-4 mb-8">
            <div className="flex flex-col gap-y-8">
              {searchPapersError ? (
                searchPapersError.response.data ===
                "Must provide search topics for us to search with" ? (
                  <span className="">You must provide key topics.</span>
                ) : (
                  <span className="text-red-500">
                    Something went wrong, please try again.
                  </span>
                )
              ) : !generalInfo?.papers?.papers ||
                generalInfo.papers.papers.length === 0 ? (
                <span>
                  There were no results found. Please run a{" "}
                  <button
                    className="text-fuchsia hover:underline"
                    onClick={() => setIsShowingResults(false)}
                  >
                    new search
                  </button>
                  .
                </span>
              ) : (
                generalInfo.papers.papers.map((paper) => (
                  <Suggestion
                    projectId={projectId}
                    recType={recType}
                    {...paper}
                    key={paper.index}
                  />
                ))
              )}
            </div>
          </ScrollArea>
        </>
      )}
    </div>
  );
}
