import axiosInstance from "./axiosInstance";

export const search_calls = async ({
  project_id,
  query,
  min_award_ceiling,
  min_award_floor,
  cost_sharing,
  closing_date,
  category_of_funding,
  start_number,
  num_calls,
}) => {
  const response = await axiosInstance({
    url: "/calls/search",
    method: "POST",
    data: {
      project_id: project_id,
      query: query,
      min_award_ceiling: min_award_ceiling,
      min_award_floor: min_award_floor,
      cost_sharing: cost_sharing,
      closing_date: closing_date,
      category_of_funding: category_of_funding,
      start_number: start_number,
      num_calls: num_calls,
    },
  });
  return response.data;
};

export const bookmark_user_call = async ({ call_id }) => {
  const response = await axiosInstance({
    url: "/calls/bookmark",
    method: "POST",
    data: {
      call_id: call_id,
      project: false,
    },
  });
  return response.data;
};

export const bookmark_project_call = async ({ project_id, call_id }) => {
  const response = await axiosInstance({
    url: "/calls/bookmark",
    method: "POST",
    data: {
      project_id: project_id,
      call_id: call_id,
      project: true,
    },
  });
  return response.data;
};

export const unbookmark_user_call = async ({ call_id }) => {
  const response = await axiosInstance({
    url: "/calls/bookmark",
    method: "DELETE",
    data: {
      call_id: call_id,
      project: false,
    },
  });
  return response.data;
};

export const unbookmark_project_call = async ({ project_id, call_id }) => {
  const response = await axiosInstance({
    url: "/calls/bookmark",
    method: "DELETE",
    data: {
      project_id: project_id,
      call_id: call_id,
      project: true,
    },
  });
  return response.data;
};
export const search_similar_calls = async ({ call_id, project_id }) => {
  const response = await axiosInstance({
    url: "/calls/similar_search",
    method: "POST",
    data: {
      project_id: project_id,
      call_id: call_id,
    },
  });
  return response.data;
};

export const get_user_bookmarked_calls = async () => {
  const response = await axiosInstance({
    url: "/calls/bookmark",
    method: "GET",
    params: { project: false },
  });
  return response.data;
};
