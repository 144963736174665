import { Dialog } from "@/components/styled/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { Transition } from "@headlessui/react";
import { add_recommended_paper } from "api";
import { LoadingSpinner } from "components";
import SlideUp from "components/animations/SlideUp";
import { useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function Suggestion({
  projectId,
  authors,
  index,
  link,
  published_date,
  summary,
  title,
  recType,
}) {
  const {
    isMutating: isUpdating,
    error: isAddPaperError,
    trigger,
  } = useSWRMutation(
    `add_recommended_paper/${projectId}/${index}`,
    () => add_recommended_paper({ project_id: projectId, rec_id: index }),
    {
      onSuccess: () => {
        setIsRelevant(true);
        mutate(`get_papers?${projectId}`, undefined, { revalidate: false });

        mutate(
          `/project/idea/background_materials/${projectId}?rec_type=${recType}`,
          (d) => ({
            ...d,
            papers: {
              ...d.papers,
              papers: d.papers.papers.filter((p) => p.title !== title),
            },
          })
        );
      },
    }
  );

  const [isRelevant, setIsRelevant] = useState(false);

  const handleSetRelevant = async () => {
    if (!isRelevant) {
      trigger();
    }
  };

  return (
    <Dialog
      trigger={
        <div className="flex flex-row border-[1px] rounded-lg p-4 hover:border-fuchsia text-left">
          <div className="basis-4/5 gap-y-1 flex flex-col grow-0">
            <div className="flex flex-row justify-between">
              <a
                href={link}
                rel="noreferrer"
                target="_blank"
                className="hover:text-fuchsia text-2xl hover:underline font-semibold"
                onClick={(e) => e.stopPropagation()}
              >
                {title}
                <span className="inline-flex">
                  <FaExternalLinkAlt className="w-5 h-5" />
                </span>
              </a>
            </div>
            <div className="flex gap-x-2 text-base">
              {authors ? authors.join(", ") : ""}
            </div>

            {summary?.length > 0 && (
              <span className="text-base">
                {summary.substring(0, 300) + (summary.length > 300 && "...")}
              </span>
            )}

            {isAddPaperError && (
              <span className="text-red-500">
                There was an issue adding your paper. Please try again.
              </span>
            )}
          </div>

          <div className="basis-1/5 flex flex-col justify-between items-center">
            <div className="font-bold underline">Relevant</div>
            {isUpdating ? (
              <LoadingSpinner />
            ) : (
              <input
                type="checkbox"
                className={"rounded accent- m-1.5 w-6 h-6"}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSetRelevant();
                }}
              />
            )}
            <div></div>
          </div>
        </div>
      }
      contentClassName="max-w-4xl max-h-screen overflow-y-scroll"
      content={
        <div className="text-black flex flex-col gap-y-2">
          <div>
            <a
              href={link}
              rel="noreferrer"
              target="_blank"
              className="hover:text-fuchsia text-2xl hover:underline font-semibold"
            >
              {title}{" "}
              <span className="inline-flex">
                <FaExternalLinkAlt className="w-5 h-5" />
              </span>
            </a>
          </div>
          <div className="flex gap-x-2 text-base">
            {authors ? authors.join(", ") : ""}
          </div>
          <div>{published_date}</div>
          <div>{summary}</div>
        </div>
      }
    />
  );
}
