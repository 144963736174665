import { ErrorPage, LoadingPage, Title } from "components";
import { Select } from "@/components/styled/select";
import { get_user_bookmarked_calls } from "api";
import useSWR from "swr";
import Call from "./Call";
import { useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";

export default function UserBookmarkedCalls({}) {
  const [selectedProjectId, setSelectedProjectId] = useState("all");
  const {
    data: bookmarkedCalls,
    isLoading: isBookmarkedCallsLoading,
    error: bookmarkedCallsError,
  } = useSWR(`get_user_bookmarked_calls`, () => {
    return get_user_bookmarked_calls();
  });

  if (isBookmarkedCallsLoading) return <LoadingPage />;
  if (bookmarkedCallsError) return <ErrorPage />;

  const projects = [
    ...new Map(
      bookmarkedCalls?.map((item) =>
        item.project_id === null
          ? [
              "user",
              { project_id: "user", project_name: "No Attached Project" },
            ]
          : [
              item.project_id,
              { project_id: item.project_id, project_name: item.project_name },
            ]
      )
    ).values(),
  ];
  return (
    <div className="bg-white flex flex-row grow rounded-lg p-4 gap-y-4 gap-x-2 divide-x-2 h-full w-full">
      <div className="flex flex-col w-full gap-y-4">
        <div className="flex justify-between">
          <Title className="text-4xl" value="Bookmarked Calls" />
          {/* <Link className="hover:underline hover:text-fuchsia" to={"/calls/search-calls"}>Search for more calls</Link> */}
        </div>

        {bookmarkedCalls?.length > 0 && (
          <>
            <div>
              <Select
                value={selectedProjectId}
                onChange={(v) => setSelectedProjectId(v)}
                options={[
                  { value: "all", label: "All Projects" },
                  ...projects.map(({ project_id, project_name }) => ({
                    value: project_id,
                    label: project_name,
                  })),
                ]}
              />
              {/* <option value={'all'}>All Projects</option>
                {projects.map(({ project_id, project_name }) => <option key={project_id} value={project_id}>{project_name}</option>)}
              </Select> */}
            </div>
            {/* <div className="basis-full bg-zinc-200 flex flex-col w-full rounded-lg p-4 gap-y-8 overflow-auto"> */}
            <ScrollArea className="p-4">
              <div className="flex flex-col gap-y-8">
                {selectedProjectId === "all"
                  ? bookmarkedCalls?.map((call) => (
                      <Call key={call.call_id} {...call} />
                    ))
                  : selectedProjectId === "user"
                  ? bookmarkedCalls
                      ?.filter((p) => p.project_id == null)
                      ?.map((call) => <Call key={call.call_id} {...call} />)
                  : bookmarkedCalls
                      ?.filter((p) => p.project_id == selectedProjectId)
                      ?.map((call) => <Call key={call.call_id} {...call} />)}
              </div>
            </ScrollArea>
          </>
        )}
      </div>
    </div>
  );
}
