export default function TextInput({ className, type, title, placeholder, input, setInput, maxlength, readOnly }) {
  return (
    <label className={"block w-full h-full " + className}>
      <span className="font-bold text-zinc-600">{title}</span>
      <input
        readOnly={readOnly}
        type={type}
        value={input || ""}
        onChange={(e) => setInput(e.target.value)}
        maxLength={maxlength}
        className={"py-1 px-2 block w-full border-2 text-zinc-600 placeholder-zinc-400 rounded-md "}
        placeholder={placeholder}
      />
    </label>
  );
}
