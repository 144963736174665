import { LoadingSpinner, Title, Toggle } from "components";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { Tooltip } from "@/components/styled/tooltip";

import { useState } from "react";
import { search_calls, search_similar_calls } from "api";
import SearchBar from "./SearchBar";
import SuggestedCall from "./SuggestedCall";
import { Input } from "@/components/styled/input";
import Select from "react-select";
import useSWR, { mutate } from "swr";
import { MdOutlineExpandMore } from "react-icons/md";
import SlideUp from "components/animations/SlideUp";
import useSWRMutation from "swr/mutation";
import { DatePicker } from "@/components/ui/datepicker";
import { format } from "date-fns";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useParams } from "react-router-dom";
import { PageHelp } from "@/components/styled/pageHelp";
import { Switch } from "@/components/ui/switch";

const fundingOptions = [
  { value: "ACA", label: "Affordable Care Act" },
  { value: "AG", label: "Agriculture" },
  { value: "AR", label: "Arts" },
  { value: "BC", label: "Business and Commerce" },
  { value: "CD", label: "Community Development" },
  { value: "CP", label: "Consumer Protection" },
  { value: "DPR", label: "Disaster Prevention and Relief" },
  { value: "ED", label: "Education" },
  { value: "ELT", label: "Employment, Labor and Training" },
  { value: "EN", label: "Energy" },
  { value: "ENV", label: "Environment" },
  { value: "FN", label: "Food and Nutrition" },
  { value: "HL", label: "Health" },
  { value: "HO", label: "Housing" },
  { value: "HU", label: "Humanities" },
  { value: "ISS", label: "Income Security and Social Services" },
  { value: "IS", label: "Information and Statistics" },
  { value: "LJL", label: "Law, Justice and Legal Services" },
  { value: "NR", label: "Natural Resources" },
  { value: "RA", label: "Recovery Act" },
  { value: "RD", label: "Regional Development" },
  {
    value: "ST",
    label: "Science and Technology and other Research and Development",
  },
  { value: "T", label: "Transportation" },
  { value: "O", label: "Other" },
];

export default function SearchCalls() {
  const { projectId } = useParams();

  const [query, setQuery] = useState("");
  const [grantCeiling, setGrantCeiling] = useState(0);
  const [grantFloor, setGrantFloor] = useState(0);
  const [closingDate, setClosingDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [costSharing, setCostSharing] = useState(false);
  const [fundingCategory, setFundingCategory] = useState([]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const {
    data: calls,
    isLoading: isLoadingCalls,
    error: callsError,
  } = useSWR(query ? `search_calls/${query}` : null, () =>
    search_calls({
      project_id: parseInt(projectId),
      query: query,
      min_award_ceiling: grantCeiling,
      min_award_floor: grantFloor,
      cost_sharing: costSharing,
      closing_date: closingDate,
      category_of_funding:
        fundingCategory?.length > 0
          ? fundingCategory.map((c) => c.value)
          : undefined,
    })
  );

  const {
    isMutating: isSearchingSimilarCalls,
    error: searchSimilarCallsError,
    trigger: searchSimilarCalls,
  } = useSWRMutation(
    `search_similar_calls`,
    (_, { arg }) =>
      search_similar_calls({
        call_id: arg,
        project_id: parseInt(projectId),
      }),
    {
      onSuccess: (d) => {
        mutate(`search_calls/${query}`, (_) => d, { revalidate: false });
      },
    }
  );

  const onSearch = (q) => {
    setIsFiltersOpen(false);
    setQuery(q);
  };

  const handleClosingDate = (newDate) => {
    const formattedDate = format(newDate, "yyyy-MM-dd");
    setClosingDate(formattedDate);
  };

  return (
    <div className="bg-white flex flex-row grow rounded-lg p-4 gap-y-4 gap-x-2 divide-x-2 h-full w-full">
      <div className="flex flex-col w-full gap-y-4">
        <div className="flex justify-between">
          <Title className="text-4xl" value="Search Calls" />
          <PageHelp
            title="Grant Space"
            content={
              <div className="flex flex-col gap-y-4 text-black">
                <div>
                  The Grant Space is dedicated to discovering and managing
                  funding opportunities.
                </div>

                <div>
                  <h2 className="font-semibold">Selected Call</h2>
                  View all details about the grant you are applying for. If you
                  haven't selected a call yet, you can do so here.
                </div>
                <div>
                  <h2 className="font-semibold">Recommend Calls</h2>
                  Using the information you provided in the Research Idea and
                  Background Material sections of the Ideation Space, we will
                  suggest funding opportunities we think may be relevant to you.
                </div>
                <div>
                  <h2 className="font-semibold">Search For Calls</h2>A search
                  tool for finding calls.
                </div>
                <div>
                  <h2 className="font-semibold">Bookmarked Calls</h2>
                  Keep track of important calls by bookmarking them. This
                  section shows all calls bookmarked by you and any other
                  collaborators on the project.
                </div>
              </div>
            }
          />
        </div>
        <SearchBar onSubmit={onSearch} />

        <div>
          <button
            className="flex items-center text-base group hover:text-fuchsia underline"
            onClick={() => setIsFiltersOpen((o) => !o)}
          >
            {isFiltersOpen ? "Hide" : "Show"} Filters
            <MdOutlineExpandMore className={"group-data-[open]:rotate-180"} />
          </button>
        </div>
        <SlideUp show={isFiltersOpen}>
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <div className="flex flex-col w-40">
              <Tooltip
                trigger={
                  <span className="text-base underline">Min. Award:</span>
                }
                content="We will only search for calls that have an award that is higher than the value you provide here."
              />

              <Input
                className="w-full pl-1"
                type="number"
                value={grantFloor}
                onChange={(v) => setGrantFloor(v)}
              />
            </div>

            <div className="flex flex-col">
              <span className="text-base underline">
                Earliest Closing Date:
              </span>
              <DatePicker value={closingDate} onChange={handleClosingDate} />
            </div>
            <div className="flex flex-col">
              <Tooltip
                trigger={
                  <span className="text-base underline">Cost Sharing:</span>
                }
                content="This filter indicates if you want to search for calls with a Cost Sharing or Matching requirement. If turned off, we will only search for ones without. If turned on, we will search for either."
              />
              <Switch
                checked={costSharing}
                onClick={() => {
                  setCostSharing((p) => !p);
                }}
              ></Switch>
            </div>
            <div className="flex flex-col w-full">
              <div>
                <Tooltip
                  trigger={
                    <span className="text-base underline">
                      Funding Category:
                    </span>
                  }
                  content="This filter will only search for calls if they belong to any of the selected categories. Leave blank if you would like to search for any category."
                />
              </div>

              <Select
                styles={{
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    outlineWidth: "2",
                    outlineStyle: "solid",
                    zIndex: 50,
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    transition: "transition",
                    overflowX: "scroll",
                    maxHeight: state.isFocused ? "100%" : "2.5em",
                    overflowY: "scroll",
                    maxWidth: "100%",
                    borderColor: state.isFocused ? "fuchsia" : "zinc-300",
                  }),
                }}
                closeMenuOnSelect={false}
                isClearable
                isMulti
                onChange={(newValue) => setFundingCategory(newValue)}
                options={fundingOptions}
                value={fundingCategory}
                placeholder={"Leave empty to search all categories"}
              />
            </div>
          </div>
        </SlideUp>

        {callsError ||
          (searchSimilarCallsError && (
            <span className="text-red-500">
              There was an issue getting calls. Please try again.
            </span>
          ))}
        {isLoadingCalls || isSearchingSimilarCalls ? (
          <div className="flex flex-col items-center justify-center">
            <LoadingSpinner className="text-fuchsia h-20 w-20" />
          </div>
        ) : (
          calls && (
            <ScrollArea className="p-4">
              <div className="flex flex-col gap-y-8">
                {calls?.map((call) => (
                  <SuggestedCall
                    key={call.call_id}
                    {...call}
                    query={query}
                    projectId={projectId}
                    searchSimilarCalls={searchSimilarCalls}
                  />
                ))}
              </div>
            </ScrollArea>
          )
        )}
      </div>
    </div>
  );
}
