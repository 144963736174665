import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipArrow,
} from "@/components/ui/tooltip";
import { IoMdHelpCircle } from "react-icons/io";

/**
 *
 * @param {*} trigger
 * @param {*} content
 * @returns
 */
function t({ trigger, content }) {
  return (
    <TooltipProvider
      delayDuration={0}
      skipDelayDuration={300}
      disableHoverableContent
    >
      <Tooltip>
        <TooltipTrigger className="flex items-center">
          {trigger}
          <IoMdHelpCircle className="fill-black text-white -translate-y-0.5" />
        </TooltipTrigger>
        <TooltipContent className="max-w-2xl bg-offblack text-white">
          {content}
          <TooltipArrow className="fill-offblack" />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export { t as Tooltip };
