import { add_key_topic, generate_key_topics, remove_key_topic } from "api";
import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { mutate } from "swr";
import { Button } from "@headlessui/react";
import { FaRegLightbulb } from "react-icons/fa";
import useSWRMutation from "swr/mutation";
import { LoadingSpinner } from "components";
import { Tooltip } from "@/components/styled/tooltip";

const createOption = (label) => ({
  label,
  value: label,
});

export default function KeyTopics({ projectId, key_topics, recType }) {
  const {
    isMutating: isGeneratingKeytopics,
    error: isGenerateKeytopicsError,
    trigger: handleRegenerateKeyTopics,
  } = useSWRMutation(
    `generate_key_topics`,
    () => generate_key_topics({ project_id: projectId, paper_type: recType }),
    {
      onSuccess: ({ key_topics }) => {
        mutate(
          `/project/idea/background_materials/${projectId}?rec_type=${recType}`,
          (data) => {
            return { ...data, key_topics: key_topics };
          },
          { revalidate: false }
        );
      },
    }
  );

  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(
    key_topics
      ? key_topics.map((t) => {
          return { label: t, value: t };
        })
      : []
  );
  useEffect(() => {
    setValue(
      key_topics
        ? key_topics.map((t) => {
            return { label: t, value: t };
          })
        : []
    );
  }, [key_topics]);
  const [isError, setIsError] = useState(false);

  const handleKeyDown = async (e) => {
    if (e.key === "Enter" || e.key === "Tab" || e.key === ",") {
      e.preventDefault();
      if (isError) setIsError(false);
      if (!inputValue) return;
      if (value.length === 10) return;
      if (value.find((v) => v.value === inputValue)) {
        setIsError(true);
        return;
      }
      // Add a key topic
      try {
        await add_key_topic({
          project_id: projectId,
          key_topic: inputValue,
          paper_type: recType,
        });
        mutate(
          `/project/idea/background_materials/${projectId}?rec_type=${recType}`,
          (data) =>
            data?.key_topics
              ? { ...data, key_topics: [...data.key_topics, inputValue] }
              : { ...data, key_topics: [inputValue] },
          { revalidate: false }
        );
      } catch (e) {
        // TODO: error handling
        console.error(e);
      }

      setValue((p) => [...p, createOption(inputValue)]);
      setInputValue("");
    }
  };

  const handleInputChange = async (newValue, context) => {
    if (context.action === "input-change") {
      setInputValue(newValue);
    } else if (context.action === "input-blur") {
      if (value.length === 10) return;
      const { prevInputValue } = context;
      if (prevInputValue) {
        try {
          await add_key_topic({
            project_id: projectId,
            key_topic: prevInputValue,
            paper_type: recType,
          });
          mutate(
            `/project/idea/background_materials/${projectId}?rec_type=${recType}`,
            (data) =>
              data?.key_topics
                ? { ...data, key_topics: [...data.key_topics, prevInputValue] }
                : { ...data, key_topics: [prevInputValue] },
            { revalidate: false }
          );
        } catch (e) {
          // TODO: error handling
          console.error(e);
        }
      }
      setInputValue("");
    }
  };

  const handleDeleteKeyTopics = async (newValue, context) => {
    // Delete key topic from cache
    if (context.action === "remove-value" || context.action === "pop-value") {
      const keyTopic = context.removedValue.value;
      try {
        await remove_key_topic({
          project_id: projectId,
          key_topic: keyTopic,
          paper_type: recType,
        });
        mutate(
          `/project/idea/background_materials/${projectId}?rec_type=${recType}`,
          (data) => {
            return {
              ...data,
              key_topics: data.key_topics.filter((v) => v !== keyTopic),
            };
          },
          { revalidate: false }
        );
      } catch (e) {
        console.error(e);
      }
    }
    setValue(newValue);
  };

  return (
    <div className="flex flex-col w-full gap-y-2 max-w-full">
      <div className="flex gap-x-2">
        <Tooltip
          trigger={
            <span className="text-xl font-semibold underline">
              Key Topics to Search
            </span>
          }
          content="These are the topics we use to search for more papers for you. We generate them based on your idea, description, and any papers you have provided so far. There is a limit of 10 key topics. Press 'Enter' to submit a key topic."
        />

        {key_topics.length === 0 && (
          <Button
            disabled={isGeneratingKeytopics}
            className="btn-fuchsia text-base "
            onClick={handleRegenerateKeyTopics}
          >
            <FaRegLightbulb className="inline-flex" /> Suggestions
          </Button>
        )}
      </div>
      {isGeneratingKeytopics ? (
        <>
          <LoadingSpinner /> Generating suggestions...
        </>
      ) : (
        <CreatableSelect
          className="min-w-12 p-0 text-sm border-input rounded-md border-[1px] cursor-default py-1"
          classNames={{
            container: ({ isFocused }) =>
              `${
                isFocused
                  ? " ring-2 ring-offset-background ring-offset-2 ring-ring"
                  : ""
              }`,
          }}
          styles={{
            control: () => null,
          }}
          components={{
            DropdownIndicator: null,
            ClearIndicator: null,
          }}
          inputValue={inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={handleDeleteKeyTopics}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={() => {
            setIsError(false);
          }}
          placeholder="Add a key topic..."
          value={value}
        />
      )}

      {value.length >= 10 && (
        <span className="text-sm">You are at the limit of 10 key topics.</span>
      )}
      {isGenerateKeytopicsError && (
        <span className="text-red-500 text-sm">
          There was an issue generating key topics. Please try again.
        </span>
      )}
      {isError && (
        <span className="text-red-500 text-sm">Key topic already exists.</span>
      )}
    </div>
  );
}
