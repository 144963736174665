import { FaChevronDown } from "react-icons/fa";
import { edit_project_roles, remove_project_role } from "api";
import useSWRMutation from "swr/mutation";
import { LoadingSpinner } from "components";
import { mutate } from "swr";
import { Dropdown } from "@/components/styled/dropdown";

export default function EditRole({
  email,
  relationship,
  research_name,
  user_id,
  user_name,
  projectId,
  hasEditorAccess,
}) {
  const {
    isMutating: isUpdatingUser,
    error: isUpdatingUserError,
    trigger: updateUser,
  } = useSWRMutation(
    `edit_project_roles`,
    async (_, { arg }) => {
      await edit_project_roles({
        project_id: projectId,
        new_roles: [{ user_id: user_id, access: arg }],
      });
      return arg;
    },
    {
      onSuccess: (newRole) => {
        mutate(
          `get_project/${projectId}`,
          (d) => ({
            ...d,
            users: d.users.map((u) =>
              u.user_id === user_id ? { ...u, relationship: newRole } : { ...u }
            ),
          }),
          { revalidate: false }
        );
      },
    }
  );

  const {
    isMutating: isDeletingUser,
    error: isDeletingUserError,
    trigger: deleteUser,
  } = useSWRMutation(
    `remove_project_role`,
    () =>
      remove_project_role({ project_id: projectId, remove_user_id: [user_id] }),
    {
      onSuccess: () => {
        mutate(
          `get_project/${projectId}`,
          (d) => {
            return {
              ...d,
              users: d.users.filter((u) => u.user_id !== user_id),
            };
          },
          { revalidate: false }
        );
      },
    }
  );

  const handleOnClick = (role) => {
    updateUser(role);
  };

  return (
    <div className="flex justify-between hover:bg-light-grey p-1 rounded-md">
      <div className="flex flex-col">
        <span>{user_name}</span>
        <span>{email}</span>
        {isUpdatingUserError && (
          <span className="text-red-500">
            There was an issue updating this user's role. Please try again.
          </span>
        )}
        {isDeletingUserError && (
          <span className="text-red-500">
            There was an issue removing this user from the project. Please try
            again.
          </span>
        )}
      </div>
      {isUpdatingUser || isDeletingUser ? (
        <button
          disabled={true}
          className="flex items-center gap-x-2 opacity-50 p-1 rounded-md"
        >
          Updating <LoadingSpinner />{" "}
        </button>
      ) : relationship === "owner" || !hasEditorAccess ? (
        <button
          disabled={true}
          className="flex items-center gap-x-2 opacity-50 p-1 rounded-md capitalize"
        >
          {relationship} <FaChevronDown />{" "}
        </button>
      ) : (
        <Dropdown
          trigger={<span>{relationship}</span>}
          options={[
            <button className="w-full" onClick={() => handleOnClick("viewer")}>
              Viewer
            </button>,
            <button className="w-full" onClick={() => handleOnClick("editor")}>
              Editor
            </button>,
            <button className="w-full" onClick={() => deleteUser()}>
              Remove Access
            </button>,
          ]}
        />
      )}
    </div>
  );
}
