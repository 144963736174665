import { useState } from "react";
import { FaShareAlt } from "react-icons/fa";
import CreatableSelect from "react-select/creatable";
import useSWRMutation from "swr/mutation";
import { Dialog } from "@/components/styled/dialog";
import { Select } from "@/components/styled/select";

import { add_project_roles } from "api";
import EditRole from "./EditRole";
import { LoadingSpinner } from "components";
import { mutate } from "swr";

const createOption = (label) => ({
  label,
  value: label,
});

export default function ShareProject({
  project_name,
  projectId,
  hasEditorAccess,
  users,
}) {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);
  const [roleAccess, setRoleAccess] = useState("viewer");

  const {
    isMutating: isAddingUsers,
    error: isAddingUsersError,
    trigger: addUsers,
  } = useSWRMutation(
    `add_users`,
    () =>
      add_project_roles({
        project_id: projectId,
        new_emails: value.map((v) => v.value),
        access: roleAccess,
      }),
    {
      onSuccess: () => {
        mutate(`get_project/${projectId}`);
        setInputValue("");
        setValue([]);
      },
    }
  );

  const handleKeyDown = async (e) => {
    let trimmedInputValue = inputValue.trim();
    if (!trimmedInputValue) return;
    if (
      e.key === "Enter" ||
      e.key === "Tab" ||
      e.key === "," ||
      e.key === " "
    ) {
      if (value.find((v) => v.value === trimmedInputValue)) {
        return;
      }

      setValue((p) => [...p, createOption(trimmedInputValue)]);
      setInputValue("");
      e.preventDefault();
    }
  };

  const handleInputChange = async (newValue, context) => {
    if (context.action === "input-change") {
      setInputValue(newValue);
    }
  };

  const handleDeleteUser = async (newValue, context) => {
    setValue(newValue);
  };

  return (
    <>
      <Dialog
        className="text-sm rounded-lg btn-fuchsia flex h-fit items-center gap-x-2"
        trigger={
          <>
            Share <FaShareAlt />
          </>
        }
        title={`Share "${project_name}"`}
        content={
          <div className="flex flex-col gap-y-4">
            {hasEditorAccess && (
              <>
                <div className="flex w-full gap-x-2">
                  <CreatableSelect
                    styles={{
                      container: (baseStyles) => ({
                        ...baseStyles,
                        width: "100%",
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        transition: "transition",
                        overflowX: "scroll",
                        maxHeight: state.isFocused ? "100%" : "2.5em",
                        overflowY: "scroll",
                        maxWidth: "100%",
                        width: "100%",
                        borderColor: "zinc-300",
                      }),
                    }}
                    components={{
                      DropdownIndicator: null,
                      ClearIndicator: null,
                    }}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={handleDeleteUser}
                    onInputChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Add people (using their email)"
                    value={value}
                  />

                  <Select
                    className="max-w-fit"
                    value={roleAccess}
                    onChange={(v) => setRoleAccess(v)}
                    options={[
                      { label: "Viewer", value: "viewer" },
                      { label: "Editor", value: "editor" },
                    ]}
                  />
                </div>

                {isAddingUsers ? (
                  <button disabled={true} className="btn-fuchsia">
                    <LoadingSpinner /> Adding
                  </button>
                ) : (
                  <div>
                    <button
                      disabled={value.length === 0}
                      className="btn-fuchsia"
                      onClick={addUsers}
                    >
                      Share
                    </button>
                  </div>
                )}

                {isAddingUsersError && (
                  <span className="text-red-500">
                    There was an issue adding users to the project. Please try
                    again
                  </span>
                )}
              </>
            )}
            <div className="flex flex-col border-y-2 pt-2 overflow-y-scroll max-h-52">
              {users.length > 0 ? (
                users.map((user) => (
                  <EditRole
                    hasEditorAccess={hasEditorAccess}
                    key={user.user_id}
                    projectId={projectId}
                    {...user}
                  />
                ))
              ) : (
                <span>This project is shared with no other users.</span>
              )}
            </div>
          </div>
        }
      />
    </>
  );
}
