import { Textarea } from "@/components/ui/textarea";

function t({
  value,
  onChange,
  className,
  type,
  placeholder,
  maxLength,
  readOnly,
}) {
  return (
    <Textarea
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
      className={className}
      type={type}
      placeholder={placeholder}
      maxLength={maxLength}
      readOnly={readOnly}
    />
  );
}
export { t as Textarea };
