import { ErrorPage, LoadingPage, TextInput, Title } from "components";
import {
  get_profile,
  update_company_name,
  update_department,
  update_google_scholar,
  update_job_title,
  update_user_name,
  add_user_interests,
  delete_user_interests,
  update_semantic_scholar,
  delete_eligible_applicant_type,
  add_eligible_applicant_type,
  update_research_name,
} from "api";
import { useState } from "react";
import useSWR, { mutate } from "swr";
import UserInterests from "./UserInterests";
import EligibleApplicants from "./EligibleApplicants";
import { Input } from "@/components/styled/input";
import { PageHelp } from "@/components/styled/pageHelp";

export default function Profile() {
  /**
   * {
      "company_name": null,
      "department": null,
      "job_title": null,
      "scholar_link": null,
      "user_interests": null,
      "user_name": null
  }
   */

  const setUserName = (v) => setUserInfo((data) => ({ ...data, user_name: v }));
  const setResearchName = (v) =>
    setUserInfo((data) => ({ ...data, research_name: v }));
  const setUserInterests = (v) =>
    setUserInfo((data) => ({ ...data, user_interests: v }));
  const setEligibleApplicantsTypes = (v) =>
    setUserInfo((data) => ({ ...data, eligible_applicant_types: v }));
  const setGoogleScholarLink = (v) =>
    setUserInfo((data) => ({ ...data, scholar_link: v }));
  const setSemanticScholarLink = (v) =>
    setUserInfo((data) => ({ ...data, semantic_scholar_link: v }));
  const setCompanyName = (v) =>
    setUserInfo((data) => ({ ...data, company_name: v }));
  const setDepartment = (v) =>
    setUserInfo((data) => ({ ...data, department: v }));
  const setJobTitle = (v) => setUserInfo((data) => ({ ...data, job_title: v }));

  const {
    data,
    isLoading,
    error: isError,
  } = useSWR(`get_profile`, () => get_profile(), {
    onSuccess: (d) => {
      // Initialize null values to string if string type
      for (const [key, value] of Object.entries(d)) {
        if (
          key !== "eligible_applicant_types" &&
          key !== "user_interests" &&
          value === null
        ) {
          d[key] = "";
        }
      }
      setUserInfo({ ...d });
    },
  });
  const [userInfo, setUserInfo] = useState(
    data
      ? { ...data }
      : {
          company_name: "",
          department: "",
          eligible_applicant_types: null,
          job_title: "",
          research_name: "",
          scholar_link: "",
          semantic_scholar_link: "",
          user_id: 1,
          user_interests: null,
          user_name: "Hustin Cao",
        }
  );

  const [isUpdateError, setIsUpdateError] = useState(false);

  if (isLoading) return <LoadingPage />;
  if (isError) return <ErrorPage />;

  const isUserNameUpdated =
    (data?.user_name || userInfo?.user_name) &&
    data?.user_name !== userInfo?.user_name;
  const isResearchNameUpdated =
    (data?.research_name || userInfo?.research_name) &&
    data?.research_name !== userInfo?.research_name;
  const isGoogleScholarLinkUpdated =
    (data?.scholar_link || userInfo.scholar_link) &&
    data?.scholar_link !== userInfo.scholar_link;
  const isSemanticScholarLinkUpdated =
    (data?.semantic_scholar_link || userInfo.semantic_scholar_link) &&
    data?.semantic_scholar_link !== userInfo.semantic_scholar_link;
  const isCompanyNameUpdated =
    (data?.company_name || userInfo.company_name) &&
    data?.company_name !== userInfo.company_name;
  const isDepartmentUpdated =
    (data?.department || userInfo.department) &&
    data?.department !== userInfo.department;
  const isJobTitleUpdated =
    (data?.job_title || userInfo.job_title) &&
    data?.job_title !== userInfo.job_title;

  const isUserInterestsUpdated =
    // (If the user deleted an interest) Check if there is at least one item in the cached data that's not in the local data
    (data?.user_interests &&
      data?.user_interests.some((v) =>
        userInfo?.user_interests ? !userInfo?.user_interests?.includes(v) : true
      )) ||
    // (If the user added an interest) Check if there is at least one item in the local data that's not in the cached data
    (userInfo?.user_interests &&
      userInfo?.user_interests.some((v) =>
        data?.user_interests ? !data?.user_interests.includes(v) : true
      ));

  const isEligibleApplicantsUpdated =
    // (If the user deleted an interest) Check if there is at least one item in the cached data that's not in the local data
    (data?.eligible_applicant_types &&
      data?.eligible_applicant_types.some((v) =>
        userInfo?.eligible_applicant_types
          ? !userInfo?.eligible_applicant_types?.includes(v)
          : true
      )) ||
    // (If the user added an interest) Check if there is at least one item in the local data that's not in the cached data
    (userInfo?.eligible_applicant_types &&
      userInfo?.eligible_applicant_types.some((v) =>
        data?.eligible_applicant_types
          ? !data?.eligible_applicant_types.includes(v)
          : true
      ));

  const isInfoUpdated =
    isUserNameUpdated ||
    isUserInterestsUpdated ||
    isResearchNameUpdated ||
    isEligibleApplicantsUpdated ||
    isGoogleScholarLinkUpdated ||
    isSemanticScholarLinkUpdated ||
    isCompanyNameUpdated ||
    isDepartmentUpdated ||
    isJobTitleUpdated;

  const saveInfo = async () => {
    setIsUpdateError(false);
    try {
      if (isCompanyNameUpdated) {
        update_company_name({ company_name: userInfo.company_name });
      }
      if (isUserNameUpdated) {
        update_user_name({ user_name: userInfo.user_name });
      }
      if (isResearchNameUpdated) {
        update_research_name({ research_name: userInfo.research_name });
      }
      if (isGoogleScholarLinkUpdated) {
        update_google_scholar({ scholar_link: userInfo.scholar_link });
      }
      if (isSemanticScholarLinkUpdated) {
        update_semantic_scholar({
          semantic_scholar_link: userInfo.semantic_scholar_link,
        });
      }
      if (isJobTitleUpdated) {
        update_job_title({ job_title: userInfo.job_title });
      }
      if (isDepartmentUpdated) {
        update_department({ department: userInfo.department });
      }
      if (isUserInterestsUpdated) {
        // Delete any interest thats in the cached data but not the local data
        const localUserInterests = data.user_interests || [];
        localUserInterests.forEach((interest) => {
          if (
            userInfo?.user_interests
              ? !userInfo.user_interests.includes(interest)
              : true
          ) {
            delete_user_interests({ interest: interest });
          }
        });
        // Add any interest thats in the local data but not in the cached data
        userInfo?.user_interests.forEach((interest) => {
          if (
            localUserInterests ? !localUserInterests.includes(interest) : true
          ) {
            add_user_interests({ interest: interest });
          }
        });
      }
      if (isEligibleApplicantsUpdated) {
        // Delete any eligible applicant type thats in the cached data but not the local data
        data?.eligible_applicant_types?.forEach((type) => {
          if (
            userInfo?.eligible_applicant_types
              ? !userInfo.eligible_applicant_types.includes(type)
              : true
          ) {
            delete_eligible_applicant_type({ id: type });
          }
        });
        // Add any interest thats in the local data but not in the cached data
        userInfo.eligible_applicant_types?.forEach((type) => {
          if (
            data?.eligible_applicant_types
              ? !data.eligible_applicant_types.includes(type)
              : true
          ) {
            add_eligible_applicant_type({ id: type });
          }
        });
      }

      mutate(
        "get_profile",
        (data) => {
          return {
            ...data,
            user_name: userInfo.user_name,
            research_name: userInfo.research_name,
            user_interests: userInfo.user_interests,
            eligible_applicant_types: userInfo.eligible_applicant_types,
            scholar_link: userInfo.scholar_link,
            company_name: userInfo.company_name,
            department: userInfo.department,
            job_title: userInfo.job_title,
          };
        },
        { revalidate: false }
      );
    } catch (e) {
      console.log(e);
      setIsUpdateError(true);
      mutate("get_profile");
    }
  };

  return (
    <div className="bg-white flex flex-row grow rounded-lg p-4 gap-y-4 gap-x-2 divide-x-2 h-full">
      <div className="flex flex-col w-full">
        <div className="flex justify-between">
          <Title className="text-4xl" value="About You" />
          <PageHelp
            title="Profile"
            content={
              <div className="flex flex-col gap-y-4 text-black">
                <p>
                  Within the Profile you are able to update your personal
                  information to help us recommend better calls and get to know
                  your interests a bit better.
                </p>
                <p>
                  You are can also view a list of all of the calls you have
                  bookmarked along with being able to remove these bookmarks and
                  more.
                </p>
                <p>
                  Finally, you can access the billing section to update your
                  billing or subscription information.
                </p>
              </div>
            }
          />
        </div>
        {isLoading ? (
          <LoadingPage />
        ) : (
          <>
            <div className="flex justify-end">
              <button
                aria-hidden={!isInfoUpdated}
                onClick={saveInfo}
                disabled={!isInfoUpdated}
                className="btn-fuchsia aria-hidden:invisible"
              >
                Save changes
              </button>
              {isUpdateError && (
                <p className="text-red-500">
                  There was an issue saving your changes. Please try again.
                </p>
              )}
            </div>
            <div className="grid grid-cols-3 gap-4 px-1 overflow-y-scroll">
              <div className="col-span-2">
                <div className="font-semibold ">Name</div>
                <Input
                  placeholder="Name"
                  value={userInfo.user_name}
                  onChange={setUserName}
                />
              </div>
              <div className="col-span-2">
                <div className="font-semibold ">Research Name</div>
                <Input
                  value={userInfo.research_name}
                  onChange={setResearchName}
                />
              </div>

              <div className="col-span-3">
                <UserInterests
                  interests={userInfo.user_interests}
                  setInterests={setUserInterests}
                />
              </div>
              <div className="col-span-3">
                <EligibleApplicants
                  eligibleApplicantTypes={userInfo.eligible_applicant_types}
                  setEligibleApplicantsTypes={setEligibleApplicantsTypes}
                />
              </div>
              <div className="col-span-2">
                <div className="font-semibold ">Google Scholar Link</div>
                <Input
                  value={userInfo.scholar_link}
                  onChange={setGoogleScholarLink}
                />
              </div>
              <div className="col-span-2">
                <div className="font-semibold ">Semantic Scholar Link</div>

                <Input
                  value={userInfo.semantic_scholar_link}
                  onChange={setSemanticScholarLink}
                />
              </div>
              <div className="col-span-2">
                <div className="font-semibold ">Company</div>
                <Input
                  value={userInfo.company_name}
                  onChange={setCompanyName}
                />
              </div>
              <div className="col-span-2">
                <div className="font-semibold ">Department</div>
                <Input value={userInfo.department} onChange={setDepartment} />
              </div>
              <div className="col-span-2">
                <div className="font-semibold ">Job Title</div>
                <Input value={userInfo.job_title} onChange={setJobTitle} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
