import { Input } from "@/components/ui/input";

function i({ value, onChange, type, placeholder, maxLength, readOnly, min, max }) {
  return (
    <Input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
      maxLength={maxLength}
      readOnly={readOnly}
      min={min}
      max={max}
    />
  );
}

export { i as Input };
