import { Tooltip } from "@/components/styled/tooltip";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";

export default function UploadPaperViaFile({ projectId, trigger }) {
  const { acceptedFiles, getRootProps, getInputProps, open, isDragActive } =
    useDropzone({
      accept: { "application/pdf": [".pdf"] },
      maxFiles: 1,
      noClick: true,
      noKeyboard: true,
    });

  const onUploadFile = () => {
    const { name } = acceptedFiles[0];
    trigger({
      project_id: projectId,
      upload_type: "upload",
      filename: name,
      file: acceptedFiles[0],
    });
  };

  return (
    <>
      <div>
        <Tooltip
          trigger={<span className="font-semibold">Upload a PDF</span>}
          content="We currently only allow pdf files that are below 10MB in size."
        />
      </div>

      <div
        className={
          "w-full p-8 border-4 border-dashed rounded-lg " +
          (isDragActive ? "border-fuchsia" : "border-zinc-200")
        }
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {acceptedFiles.length ? (
          <div className="flex flex-col items-center justify-center ">
            <FaCloudUploadAlt className="w-20 h-20" />
            <p className="text-xl">{acceptedFiles[0].path}</p>
            <span className="h-6"></span>
            <button className="btn-fuchsia text-xl" onClick={onUploadFile}>
              Click to upload file
            </button>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center ">
            <FaCloudUploadAlt className="w-20 h-20" />
            <p className="text-xl">Drag and drop a PDF here</p>
            <p>or</p>
            <button onClick={open} className="btn-offblack text-xl">
              Click to browse files
            </button>
          </div>
        )}
      </div>
    </>
  );
}
